import React, { ReactElement } from 'react';
import { Guard } from '../Guard';
import { Navigate, useLocation } from 'react-router-dom';
import { useGuardService } from '../useGuardService';
import { Userinfo } from '../../../store/Userinfo/Userinfo.type';
import { useStoreState } from '../../../store/hooks';

export interface ProtectedRouteProps {
    mustHaveOneOf?: string[];
    mustSatisfy?: (userInfo: Userinfo) => boolean;
    element: ReactElement;
}

export const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = ({
    mustHaveOneOf,
    mustSatisfy,
    element,
}) => {
    const { user } = useStoreState(({ userinfo }) => userinfo);
    const isAuthorized = useGuardService(user, mustHaveOneOf, mustSatisfy);
    const location = useLocation();

    let DefaultFallBack = (
        <Navigate replace={true} to={{ pathname: `/401`, search: `url=${encodeURIComponent(location.pathname)}` }} />
    );

    if (user?.user_id) {
        DefaultFallBack = <Navigate replace={true} to={'/403'} />;
    }

    return (
        <Guard FallBackComponent={DefaultFallBack} isAuthorized={isAuthorized}>
            {element}
        </Guard>
    );
};
