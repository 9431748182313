import { Userinfo } from '../../store/Userinfo/Userinfo.type';
import { GuardRulesBuilder } from './GuardRules.usecase';

export const useGuardService = (
    userInfo: Userinfo,
    permissions?: string[],
    callBack?: (userInfo: Userinfo) => boolean,
): boolean => {
    if (userInfo?.role) {
        let guardRules = new GuardRulesBuilder(userInfo);
        if (permissions) {
            guardRules = guardRules.authorizePermissions(permissions);
        } else if (callBack) {
            guardRules = guardRules.shouldSatisfy(callBack);
        }

        const isAuthorized = guardRules.build().isAuthorized();
        return isAuthorized;
    }
    return false;
};
