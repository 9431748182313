import axios, { AxiosResponse } from 'axios';
import { Ingredient } from '../store/Ingredient/Ingredient.type';
import serverService from './server.service';

export class IngredientService {
    fetch(limit: number = 10, page: number = 1): Promise<AxiosResponse<[Ingredient[], number]>> {
        const skip = limit * (page - 1);
        return axios.get(`${serverService.getServer()}/ingredients?limit=${limit}&skip=${skip}`);
    }

    fetchById(ingredientId: string): Promise<AxiosResponse<Ingredient>> {
        return axios.get(`${serverService.getServer()}/ingredients/${ingredientId}`);
    }
}

export default IngredientService;
