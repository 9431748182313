import { createStore } from 'easy-peasy';
import RecipeService from '../services/recipe.service';
import UserService from '../services/user.service';
import IngredientService from '../services/ingredient.service';
import model from './model';
import FoodService from '../services/food.service';
import IngredientUnitService from '../services/ingredientUnit.service';
import RoleService from '../services/role.service';
import ScopeService from '../services/scope.service';
import StockService from '../services/stock.service';

const userService = new UserService();
const recipeService = new RecipeService();
const ingredientService = new IngredientService();
const foodService = new FoodService();
const ingredientUnitService = new IngredientUnitService();
const roleService = new RoleService();
const scopeService = new ScopeService();
const stockService = new StockService();

const store = createStore(model, {
    injections: {
        userService,
        recipeService,
        ingredientService,
        foodService,
        ingredientUnitService,
        roleService,
        scopeService,
        stockService,
    },
});

export default store;
