import { action, Action, thunk, Thunk } from 'easy-peasy';
import { Injections } from '../injections';

import { Stock } from './Stock.type';

export interface StockModel {
    stocks: Stock[];
    count: number | undefined;
    lastFetchedTime: Date | undefined;
    setStocks: Action<StockModel, Stock[]>;
    setCount: Action<StockModel, number>;
    setLastFetchedTime: Action<StockModel, void>;
    fetch: Thunk<StockModel, void, Injections>;
    fetchById: Thunk<StockModel, string, Injections>;
    create: Thunk<StockModel, Stock, Injections>;
    update: Thunk<StockModel, Stock, Injections>;
    remove: Thunk<StockModel, string, Injections>;
}

export const stockModel: StockModel = {
    stocks: [],
    count: undefined,
    lastFetchedTime: undefined,
    setStocks: action((state, payload: Stock[]) => {
        state.stocks = payload;
    }),
    setCount: action((state, payload: number) => {
        state.count = payload;
    }),
    setLastFetchedTime: action((state, _payload) => {
        state.lastFetchedTime = new Date();
    }),
    fetch: thunk(async (actions, _payload, { injections }) => {
        try {
            const { stockService } = injections;
            const stocks = await stockService.fetch();
            actions.setCount(stocks.data[1]);
            actions.setStocks(stocks.data[0]);
            actions.setLastFetchedTime();
        } catch (error) {
            console.error(error);
        }
    }),
    fetchById: thunk(async (actions, payload, { injections }) => {
        try {
            const { stockService } = injections;
            const stock = await stockService.fetchById(payload);
            return stock.data;
        } catch (error) {
            console.error(error);
        }
    }),
    create: thunk(async (actions, payload, { injections }) => {
        try {
            const { stockService } = injections;
            const stock = await stockService.create(payload);
            await actions.fetch();
            return stock.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
    update: thunk(async (actions, payload, { injections }) => {
        try {
            const { stockService } = injections;
            const stock = await stockService.update(payload);
            await actions.fetch();
            return stock.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
    remove: thunk(async (actions, payload, { injections }) => {
        try {
            const { stockService } = injections;
            const stock = await stockService.delete(payload);
            await actions.fetch();
            return stock.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
};

export default stockModel;
