/* PLOP_MODEL_IMPORT */
import stock, { StockModel } from './Stock/Stock.model';
import userinfo, { UserinfoModel } from './Userinfo/Userinfo.model';
import ingredientUnit, { IngredientUnitModel } from './IngredientUnit/IngredientUnit.model';
import food, { FoodModel } from './Food/Food.model';
import ingredient, { IngredientModel } from './Ingredient/Ingredient.model';
import recipe, { RecipeModel } from './Recipe/Recipe.model';
import scope, { ScopeModel } from './Scope/Scope.model';
import role, { RoleModel } from './Role/Role.model';
import user, { UserModel } from './User/User.model';
import i18n, { I18nModel } from './I18n/i18n.model';
import { persist } from 'easy-peasy';

export interface AppStoreModel {
    /* PLOP_MODEL_INTERFACE */
    stock: StockModel;
    userinfo: UserinfoModel;
    ingredientUnit: IngredientUnitModel;
    food: FoodModel;
    ingredient: IngredientModel;
    recipe: RecipeModel;
    scope: ScopeModel;
    role: RoleModel;
    user: UserModel;
    i18n: I18nModel;
}

const model: AppStoreModel = {
    /* PLOP_MODEL */
    stock,
    userinfo: persist(userinfo, { storage: localStorage }),
    ingredientUnit,
    food,
    ingredient,
    recipe,
    scope,
    role,
    user,
    i18n,
};

export default model;
