import axios, { AxiosResponse } from 'axios';
import { User } from '../store/User/User.type';
import serverService from './server.service';

export interface Cache {
    [key: string]: {
        date: number;
        user: User | null;
    };
}

export class UserService {
    async signIn(username: string, password: string): Promise<{ access_token: string }> {
        try {
            const userInfo = await axios.post(`${serverService.getServer()}/auth/login`, { username, password });
            return userInfo.data;
        } catch (error) {
            throw Error('Error during getting user info');
        }
    }

    signUp(username: string, password: string) {
        return axios.post(`${serverService.getServer()}/auth/signup`, { username, password });
    }

    signOut() {
        return axios.post(`${serverService.getServer()}/auth/signout`);
    }

    async fetchInfo(): Promise<User> {
        try {
            const userInfo = await axios.get(`${serverService.getServer()}/auth/userinfo`);
            return userInfo.data;
        } catch (error) {
            console.error(error);
            throw Error('Error during getting user info');
        }
    }

    fetch(limit: number = 10, page: number = 1): Promise<AxiosResponse<[User[], number]>> {
        const skip = limit * (page - 1);
        return axios.get(`${serverService.getServer()}/users?limit=${limit}&skip=${skip}`);
    }

    fetchById(userId: string): Promise<AxiosResponse<User>> {
        return axios.get(`${serverService.getServer()}/users/${userId}`);
    }

    fetchByUsername(username: string): Promise<AxiosResponse<User>> {
        return axios.get(`${serverService.getServer()}/profile/@${username}`);
    }

    fetchCache(): Promise<AxiosResponse<Cache>> {
        return axios.get(`${serverService.getServer()}/users/cache`);
    }

    create(user: User): Promise<AxiosResponse<User>> {
        return axios.post(`${serverService.getServer()}/users`, user);
    }

    update(user: User): Promise<AxiosResponse<User>> {
        return axios.put(`${serverService.getServer()}/users/${user.user_id}`, user);
    }

    updatePassword(oldPassword: string, newPassword: string): Promise<AxiosResponse<{}>> {
        return axios.put(`${serverService.getServer()}/profile/password`, { newPassword, oldPassword });
    }

    delete(userId: string): Promise<AxiosResponse<User>> {
        return axios.delete(`${serverService.getServer()}/users/${userId}`);
    }
}

export default UserService;
