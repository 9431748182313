import { action, Action, thunk, Thunk } from 'easy-peasy';
import { Injections } from '../injections';

import { Scope } from './Scope.type';

export interface ScopeModel {
    scopes: Scope[];
    count: number | undefined;
    lastFetchedTime: Date | undefined;
    setScopes: Action<ScopeModel, Scope[]>;
    setCount: Action<ScopeModel, number>;
    setLastFetchedTime: Action<ScopeModel, void>;
    fetch: Thunk<ScopeModel, void, Injections>;
    fetchById: Thunk<ScopeModel, string, Injections>;
    create: Thunk<ScopeModel, Scope, Injections>;
    update: Thunk<ScopeModel, Scope, Injections>;
    remove: Thunk<ScopeModel, string, Injections>;
}

export const scopeModel: ScopeModel = {
    scopes: [],
    count: undefined,
    lastFetchedTime: undefined,
    setScopes: action((state, payload: Scope[]) => {
        state.scopes = payload;
    }),
    setCount: action((state, payload: number) => {
        state.count = payload;
    }),
    setLastFetchedTime: action((state, _payload) => {
        state.lastFetchedTime = new Date();
    }),
    fetch: thunk(async (actions, _payload, { injections }) => {
        try {
            const { scopeService } = injections;
            const roles = await scopeService.fetch();
            actions.setCount(roles.data[1]);
            actions.setScopes(roles.data[0]);
            actions.setLastFetchedTime();
        } catch (error) {
            console.error(error);
        }
    }),
    fetchById: thunk(async (actions, payload, { injections }) => {
        try {
            const { scopeService } = injections;
            const food = await scopeService.fetchById(payload);
            return food.data;
        } catch (error) {
            console.error(error);
        }
    }),
    create: thunk(async (actions, payload, { injections }) => {
        try {
            const { scopeService } = injections;
            const role = await scopeService.create(payload);
            await actions.fetch();
            return role.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
    update: thunk(async (actions, payload, { injections }) => {
        try {
            const { scopeService } = injections;
            const role = await scopeService.update(payload);
            await actions.fetch();
            return role.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
    remove: thunk(async (actions, payload, { injections }) => {
        try {
            const { scopeService } = injections;
            const role = await scopeService.delete(payload);
            await actions.fetch();
            return role.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
};

export default scopeModel;
