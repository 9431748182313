import React, { PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';
import { useStoreState } from '../store/hooks';

const I18nProvider: React.FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    const { locale, messages } = useStoreState(({ i18n }) => i18n);

    return <IntlProvider locale={locale} messages={messages as any} children={children} />;
};

export default I18nProvider;
