const fr = {
    shared: {
        createdBy: 'Créé par',
        createdAt: 'Créé le',
        updatedAt: 'Mis à jour le',
        cancel: 'Annuler',
        new: 'Nouveau',
        veggie: 'Végé',
        expired: 'Périmé',
        shortDate: 'Date courte',
        revoked: 'Révoqué',
        description: 'Description',
        technical: 'Technique',
        seasonal: 'De saison',
        notSeasonal: 'Pas de saison',
        noResult: 'Aucun résultat',
        storageArea: {
            cupboard: 'Placard',
            fridge: 'Réfrégirateur',
            freezer: 'Congélateur',
        },
        navigation: {
            next: 'Suivant',
            previous: 'Précédent',
        },
        upload: {
            error: "Erreur lors de l'envoi de l'image. Est-ce bien une image ?",
        },
        month: {
            january: 'Janvier',
            february: 'Février',
            march: 'Mars',
            april: 'Avril',
            may: 'Mai',
            june: 'Juin',
            july: 'Juillet',
            august: 'Août',
            september: 'Septembre',
            october: 'Octobre',
            november: 'Novembre',
            december: 'Décembre',
        },
        foodType: {
            fruit: 'Fruit',
            vegetable: 'Légume',
            meat: 'Viande',
            fish: 'Poisson',
            egg: 'Oeuf',
            legume: 'Légumineuse',
            dairy_product: 'Produit laitier',
            cereal: 'Céréale',
            drink: 'Boisson',
            rice_pasta: 'Riz - Pâtes',
            null: 'Aucun type selectionné',
        },
        recipeType: {
            entree: 'Entrée',
            mainCourse: 'Plat principal',
            dessert: 'Dessert',
            sauce: 'Sauce',
            drink: 'Boisson',
        },
    },
    components: {
        account: {
            openMenu: 'Ouvrir le menu utilisateur',
            settings: 'Paramètres',
            signout: 'Se déconnecter',
            signin: 'Se connecter',
        },
    },
    header: {
        recipes: 'Recettes',
        home: 'Home',
        addRecipe: 'Partager une recette',
        admin: 'Admin',
        stocks: 'Stocks',
    },
    alert: {
        success: 'Succès',
        error: 'Erreur',
        warning: 'Attention',
        info: 'Information',
    },
    pages: {
        adminUsers: {
            search: 'Rechercher',
            create: 'Créer',
            placeholder: {
                users: 'Theo, Admin, ...',
            },
        },
        adminUser: {
            cancel: 'Annuler',
            username: 'Pseudo',
            password: 'Mot de passe',
            update: 'Mettre à jour',
            create: 'Créer',
            delete: 'Supprimer',
            activated: 'Actif',
            role: 'Rôle',
            updated: {
                success: 'Utilisateur mis à jour !',
                error: "Oops.. Erreur lors de la mise à jour de l'utilisateur..",
            },
            created: {
                success: 'Utilisateur créé !',
                error: "Oops.. Erreur lors de la création de l'utilisateur..",
            },
            removed: {
                success: 'Utilisateur supprimé !',
                error: "Oops.. Erreur lors de la suppression de l'utilisateur..",
            },
            modal: {
                delete: 'Suppression',
                desc: 'Es-tu sûr(e) de vouloir supprimer {username} ?',
                cancel: 'Annuler',
                confirm: 'Supprimer',
            },
        },
        adminScopes: {
            search: 'Rechercher',
            create: 'Créer',
            placeholder: {
                scopes: 'admin.cache.read, user.recipe.read, ...',
            },
        },
        adminScope: {
            cancel: 'Annuler',
            name: 'Nom du scope',
            update: 'Mettre à jour',
            create: 'Créer',
            delete: 'Supprimer',
            updated: {
                success: 'Scope mis à jour !',
                error: 'Oops.. Erreur lors de la mise à jour du scope..',
            },
            created: {
                success: 'Scope créé !',
                error: 'Oops.. Erreur lors de la création du scope..',
            },
            removed: {
                success: 'Scope supprimé !',
                error: 'Oops.. Erreur lors de la suppression du scope..',
            },
            modal: {
                delete: 'Suppression',
                desc: 'Es-tu sûr(e) de vouloir supprimer {label} ?',
                cancel: 'Annuler',
                confirm: 'Supprimer',
            },
        },
        adminRoles: {
            default: 'Défaut',
            scopes: '{nb} scopes',
            create: 'Créer',
            search: 'Rechercher',
            placeholder: {
                roles: 'Admin, user, ...',
            },
        },
        adminRole: {
            cancel: 'Annuler',
            name: 'Nom du role',
            update: 'Mettre à jour',
            create: 'Créer',
            delete: 'Supprimer',
            scopes: 'Scopes',
            default: 'Défaut',
            updated: {
                success: 'Role mis à jour !',
                error: 'Oops.. Erreur lors de la mise à jour du role..',
            },
            created: {
                success: 'Role créé !',
                error: 'Oops.. Erreur lors de la création du role..',
            },
            removed: {
                success: 'Role supprimé !',
                error: 'Oops.. Erreur lors de la suppression du role..',
            },
            modal: {
                delete: 'Suppression',
                desc: 'Es-tu sûr(e) de vouloir supprimer {label} ?',
                cancel: 'Annuler',
                confirm: 'Supprimer',
            },
        },
        adminIngredientUnits: {
            create: 'Créer',
            search: 'Rechercher',
            placeholder: {
                roles: 'L, mg, ...',
            },
        },
        adminIngredientUnit: {
            cancel: 'Annuler',
            name: "Nom du l'unité",
            update: 'Mettre à jour',
            create: 'Créer',
            delete: 'Supprimer',
            updated: {
                success: 'Unité mise à jour !',
                error: "Oops.. Erreur lors de la mise à jour de l'unité..",
            },
            created: {
                success: 'Unité créée !',
                error: "Oops.. Erreur lors de la création de l'unité..",
            },
            removed: {
                success: 'Unité supprimée !',
                error: "Oops.. Erreur lors de la suppression de l'unité..",
            },
            modal: {
                delete: 'Suppression',
                desc: 'Es-tu sûr(e) de vouloir supprimer {label} ?',
                cancel: 'Annuler',
                confirm: 'Supprimer',
            },
        },
        adminFoods: {
            search: 'Rechercher',
            create: 'Créer',
            placeholder: {
                foods: 'Ail, Camembert, ...',
            },
        },
        adminFood: {
            cancel: 'Annuler',
            name: "Nom de l'aliment",
            update: 'Mettre à jour',
            create: 'Créer',
            delete: 'Supprimer',
            activated: 'Actif',
            genericFood: 'Aliment générique',
            basicFood: 'Aliment basique (est-il non transformé ?)',
            foodType: "Type d'aliment",
            diet: 'Régime',
            isVegan: 'Est-il végan',
            isPorkless: 'Est-il sans porc',
            isDairyFree: 'Est-il sans produit laitier',
            isGlutenFree: 'Est-il sans gluten',
            isVegetarian: 'Est-il végétarien',
            updated: {
                success: 'Aliment mis à jour !',
                error: "Oops.. Erreur lors de la mise à jour de l'aliment..",
            },
            created: {
                success: 'Aliment créé !',
                error: "Oops.. Erreur lors de la création de l'aliment..",
            },
            removed: {
                success: 'Aliment supprimé !',
                error: "Oops.. Erreur lors de la suppression de l'aliment..",
            },
            modal: {
                delete: 'Suppression',
                desc: 'Es-tu sûr(e) de vouloir supprimer {foodName} ?',
                cancel: 'Annuler',
                confirm: 'Supprimer',
            },
        },
        adminRecipes: {
            search: 'Rechercher',
            create: 'Créer',
            placeholder: {
                recipes: 'Pâtes au fromage, pizza 4 fromages, ...',
            },
        },
        adminRecipe: {
            cancel: 'Annuler',
            title: 'Titre',
            update: 'Mettre à jour',
            create: 'Créer',
            delete: 'Supprimer',
            activated: 'Actif',
            recipeType: 'Type de recette',
            updated: {
                success: 'Recette mise à jour !',
                error: 'Oops.. Erreur lors de la mise à jour de la recette..',
            },
            created: {
                success: 'Recette créée !',
                error: 'Oops.. Erreur lors de la création de la recette..',
            },
            removed: {
                success: 'Recette supprimée !',
                error: 'Oops.. Erreur lors de la suppression de la recette..',
            },
            modal: {
                delete: 'Suppression',
                desc: 'Es-tu sûr(e) de vouloir supprimer {recipeTitle} ?',
                cancel: 'Annuler',
                confirm: 'Supprimer',
            },
        },
        signup: {
            error: {
                differentPassword: 'Ces mots de passe ne correspondent pas. Veuillez réessayer.',
            },
            password: 'Mot de passe',
            confirmPassword: 'Confirmer le mot de passe',
            nickname: 'Pseudo',
            signup: "S'inscrire",
            title: 'Inscription',
            alreadyAccount: 'Déjà un compte ?',
            connectNow: 'Se connecter maintenant',
        },
        signin: {
            error: {
                forbiddenConnection: 'Pseudo ou mot de passe incorrect.',
            },
            welcome: 'Compte créé avec succès !',
            title: 'Connexion',
            password: 'Mot de passe',
            nickname: 'Pseudo',
            rememberMe: 'Se souvenir de moi',
            forgotPassword: 'Mot de passe oublié ?',
            signin: 'Se connecter',
            register: "S'inscrire maintenant",
            noAccount: 'Pas encore de compte ?',
        },
        settings: {
            platform: 'Plateforme',
            browser: 'Navigateur',
            os: 'Système',
            expireAt: 'Expire le',
            profile: 'Profil',
            security: 'Sécurité',
            myAccount: 'Mon compte',
            latestConnections: 'Dernières connexions',
            active: 'Actif',
            revoked: 'Révoqué',
            status: 'Statut',
            expired: 'Expiré',
            nickname: 'Pseudo',
            limitedLast: 'Limité aux 10 dernières',
            createdAt: 'Créé le',
            updatedAt: 'Mis à jour le',
            personalInformation: 'Renseignements personnels',
            role: 'Rôle',
            password: 'Mot de passe',
            oldPassword: 'Ancien mot de passe',
            newPassword: 'Nouveau mot de passe',
            update: 'Mettre à jour',
            updatePassword: {
                success: 'Mot de password mis à jour !',
                error: 'Oops.. Erreur lors de la mise à jour du mot de passe..',
            },
        },
        stocks: {
            eatQuickly: 'A manger rapidement',
            myStock: 'Mon stock',
            addFood: 'Ajouter un aliment',
            purchaseDate: "Date d'achat",
            peremptionDate: 'Date de péremption',
            quantity: 'Quantité',
            unit: 'Unité',
            add: 'Ajouter',
            storageArea: 'Lieu de stockage',
            created: {
                success: 'Aliment ajouté !',
                error: "Oops.. Erreur lors de l'ajout de votre aliment..",
            },
        },
        addRecipe: {
            recipe: 'Recette',
            addPicture: 'Ajouter une image',
            ingredients: 'Ingrédients',
            addIngredient: 'Ajouter un ingrédient',
            ingredientsSample: 'Mozzarella, tomate, moutarde, beurre,...',
            steps: 'Étapes',
            validation: 'Validation',
            title: 'Titre',
            cookingTime: 'Temps de cuisson',
            preparationTime: 'Temps de préparation',
            howManyPerson: 'Pour combien de personnes ?',
            veggie: 'Végé',
            alcoholic: 'Alcoolisé',
            recipeType: 'Type de recette',
            next: 'Suivant',
            previous: 'Précédent',
            create: 'Créer',
            update: 'Mettre à jour',
            titleStep: "Titre de l'étape",
            libStep: 'Description',
            addStep: 'Ajouter une étape',
            awesomeRecipe: 'Ta recette est fantastique !',
            noTitle: 'Arf un titre me semble nécessaire malheureusement',
            noIngredient: "Une recette sans ingrédient ? Qui l'u cru ?",
            noStep: 'Waouh, aucune étape ? Sérieusement ?',
            cancel: 'Annuler',
            optional: 'Facultatif',
            updated: {
                success: 'Recette mise à jour !',
                error: 'Oops.. Erreur lors de la mise à jour de la recette..',
            },
            created: {
                success: 'Recette créée !',
                error: 'Oops.. Erreur lors de la création de la recette..',
            },
        },
        recipes: {
            recipes: 'Recettes',
            ingredients: 'Ingrédients',
            placeholder: {
                recipes: 'Hamburger, risotto, pizza,...',
                ingredients: 'Tomate, riz, fromage,...',
            },
            search: 'Rechercher',
        },
        recipe: {
            ingredients: 'Ingrédients',
            preparation: 'Préparation',
            return: 'Retour aux recettes',
            optional: 'Facultatif',
            edit: 'Éditer',
            delete: 'Supprimer',
            nbPersons: 'Pour {nb} personnes',
            removed: {
                success: 'Recette supprimée !',
                error: 'Oops.. Erreur lors de la suppression de la recette..',
            },
            modal: {
                delete: 'Suppression',
                desc: 'Es-tu sûr(e) de vouloir supprimer {recipeName} ?',
                cancel: 'Annuler',
                confirm: 'Supprimer',
            },
        },
        home: {
            title: "Qu'allons-nous manger aujourd'hui ?",
            lastestRecipesAdded: 'Dernières recettes ajoutées',
            someRecipesYouMightLike: 'Voici quelques recettes qui pourraient te plaire',
            surplusOf: 'Surplus de {foodName}',
            seeMore: 'Voir plus de recettes',
            sharedRecipes: 'Recettes partagées',
            seasonalFoods: 'Aliments de saison',
        },
    },
};

export default fr;
