import axios from 'axios';
import React, { PropsWithChildren, useEffect } from 'react';
import { useStoreState, useStoreActions } from '../store/hooks';

const TTL = 1000 * 60 * 2;
let intervalId: NodeJS.Timeout | undefined = undefined;
let axiosRequestInterceptorId: number | undefined = undefined;
let axiosResponseInterceptorId: number | undefined = undefined;

const AuthProvider: React.FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    const { user, access_token } = useStoreState(({ userinfo }) => userinfo);
    const { fetch, reset, setAccessToken } = useStoreActions(({ userinfo }) => userinfo);

    useEffect(() => {
        console.log(axiosRequestInterceptorId);
        console.log(axiosResponseInterceptorId);
        if (access_token.length > 0) {
            if (axiosRequestInterceptorId === undefined) {
                axiosRequestInterceptorId = axios.interceptors.request.use((config) => {
                    if (config?.headers) {
                        config.headers['Authorization'] = `Bearer ${access_token}`;
                    }
                    return config;
                });
                fetch();
            }
            if (axiosResponseInterceptorId === undefined) {
                axiosResponseInterceptorId = axios.interceptors.response.use(
                    (response) => {
                        return response;
                    },
                    async (error) => {
                        console.log(error);
                        console.log(error.response.status);
                        if (401 === parseInt(error.response.status)) {
                            await reset();
                        } else {
                            return Promise.reject(error);
                        }
                    },
                );
            }
        } else {
            if (axiosRequestInterceptorId !== undefined) {
                console.log(`Interceptor Request ejected`);
                axios.interceptors.request.eject(axiosRequestInterceptorId);
                axiosRequestInterceptorId = undefined;
            }
            if (axiosResponseInterceptorId !== undefined) {
                console.log(`Interceptor Response ejected`);
                axios.interceptors.request.eject(axiosResponseInterceptorId);
                axiosResponseInterceptorId = undefined;
            }
        }
    }, [access_token, fetch, reset, setAccessToken, user]);

    useEffect(() => {
        if (user?.user_id) {
            if (!intervalId) {
                intervalId = setInterval(() => {
                    fetch();
                    console.log(`User fetched at: ${new Date()}`);
                }, TTL);
            }
        } else {
            if (intervalId) {
                clearInterval(intervalId);
                intervalId = undefined;
            }
        }
    }, [fetch, user]);

    return <>{children}</>;
};

export default AuthProvider;
