// Dependencies
import React, { Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { SemipolarLoading } from 'react-loadingg';

// Components
import ScrollToTop from './components/ScrollToTop';
import ErrorBoundaryComponent from './components/ErrorBoundary';
import SidePanel from './components/SidePanel';
import { SidePanelItem } from './components/SidePanel/SidePanel';
import AlertProvider from './components/Alert/AlertProvider';
import { Header, HeaderItem } from './components/Header';

// Stores
import { Scopes } from './store/Scope/Scope.type';

// Guards
import { ProtectedRoute } from './guards/role-guards';

// Providers
import Providers from './providers';
import AdminIngredientUnitPage from './pages/AdminIngredientUnit';
import AdminIngredientUnitsPage from './pages/AdminIngredientUnits';

// Routes
const Home = React.lazy(() => import('./pages/Home'));
/* PLOP_INJECT_LAZY_IMPORT */
const Stocks = React.lazy(() => import('./pages/Stocks'));
const AdminDashboard = React.lazy(() => import('./pages/AdminDashboard'));
const AdminCache = React.lazy(() => import('./pages/AdminCache'));
const AdminScope = React.lazy(() => import('./pages/AdminScope'));
const AdminScopes = React.lazy(() => import('./pages/AdminScopes'));
const AdminRecipe = React.lazy(() => import('./pages/AdminRecipe'));
const AdminRecipes = React.lazy(() => import('./pages/AdminRecipes'));
const AdminRole = React.lazy(() => import('./pages/AdminRole'));
const AdminRoles = React.lazy(() => import('./pages/AdminRoles'));
const AdminUser = React.lazy(() => import('./pages/AdminUser'));
const AdminUsers = React.lazy(() => import('./pages/AdminUsers'));
const AdminFood = React.lazy(() => import('./pages/AdminFood'));
const AdminFoods = React.lazy(() => import('./pages/AdminFoods'));
const Forbidden = React.lazy(() => import('./pages/Forbidden'));
const AddRecipe = React.lazy(() => import('./pages/AddRecipe'));
const Settings = React.lazy(() => import('./pages/Settings'));
const Profile = React.lazy(() => import('./pages/Profile'));
const SignUp = React.lazy(() => import('./pages/SignUp'));
const SignIn = React.lazy(() => import('./pages/SignIn'));
const Recipe = React.lazy(() => import('./pages/Recipe'));
const Recipes = React.lazy(() => import('./pages/Recipes'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const Unauthorized = React.lazy(() => import('./pages/Unauthorized'));

const headerItems: HeaderItem[] = [
    { libId: 'header.home', link: '/', exact: true },
    { libId: 'header.recipes', link: '/recipes', exact: false },
    { libId: 'header.addRecipe', link: '/add-recipe', exact: true },
    { libId: 'header.stocks', link: '/stocks', exact: false, mustHaveOneOf: [Scopes._USER_STOCK_READ_] },
    {
        libId: 'header.admin',
        link: '/admin',
        exact: false,
        mustHaveOneOf: [Scopes._ADMIN_FOOD_READ_, Scopes._ADMIN_ROLE_READ_, Scopes._ADMIN_USER_READ_],
    },
];

const adminSidePanelItems: SidePanelItem[] = [
    {
        link: '/admin/dashboard',
        exact: false,
        mustHaveOneOf: [Scopes._ADMIN_FOOD_READ_, Scopes._ADMIN_USER_READ_, Scopes._ADMIN_ROLE_READ_],
        icon: 'speed',
    },
    { link: '/admin/users', exact: false, mustHaveOneOf: [Scopes._ADMIN_USER_READ_], icon: 'people' },
    { link: '/admin/foods', exact: false, mustHaveOneOf: [Scopes._ADMIN_FOOD_READ_], icon: 'bakery_dining' },
    { link: '/admin/recipes', exact: false, mustHaveOneOf: [Scopes._ADMIN_RECIPE_READ_], icon: 'restaurant' },
    {
        link: '/admin/ingredient-units',
        exact: false,
        mustHaveOneOf: [Scopes._ADMIN_INGREDIENT_UNIT_READ_],
        icon: 'science',
    },
    { link: '/admin/roles', exact: false, mustHaveOneOf: [Scopes._ADMIN_ROLE_READ_], icon: 'security' },
    { link: '/admin/scopes', exact: false, mustHaveOneOf: [Scopes._ADMIN_SCOPE_READ_], icon: 'loyalty' },
    { link: '/admin/cache', exact: false, mustHaveOneOf: [Scopes._ADMIN_CACHE_READ_], icon: 'storage' },
];

const Layout: React.FC = () => {
    return (
        <>
            <Header items={headerItems} />
            <ScrollToTop id="base-layout">
                <div
                    id="base-layout"
                    style={{
                        height: 'calc(100vh - 65px)',
                        width: '100vw',
                        overflowY: 'auto',
                    }}
                    className="bg-gray-50 relative"
                >
                    <AlertProvider>
                        <Outlet />
                    </AlertProvider>
                </div>
            </ScrollToTop>
        </>
    );
};

const LayoutAdmin: React.FC = () => {
    return (
        <>
            <div className="flex h-full w-full">
                <SidePanel items={adminSidePanelItems} />
                <div
                    style={{
                        height: '100%',
                        overflowY: 'auto',
                    }}
                    className="w-full"
                >
                    <Outlet />
                </div>
            </div>
        </>
    );
};

const App: React.FunctionComponent<{}> = () => {
    return (
        <Providers>
            <ScrollToTop>
                <ErrorBoundaryComponent>
                    <Suspense
                        fallback={
                            <div>
                                <SemipolarLoading size="large" color="#10b981" />
                            </div>
                        }
                    >
                        <Routes>
                            <Route path="sign-in" element={<SignIn />} />
                            <Route path="sign-up" element={<SignUp />} />
                            <Route path="" element={<Layout />}>
                                <Route path={''} element={<Home />} />
                                {/* PLOP_INJECT_ROUTE */}
                                <Route
                                    path="stocks"
                                    element={
                                        <ProtectedRoute
                                            mustHaveOneOf={[Scopes._USER_STOCK_READ_]}
                                            element={<Stocks />}
                                        />
                                    }
                                />
                                <Route
                                    path="add-recipe"
                                    element={
                                        <ProtectedRoute
                                            mustHaveOneOf={[Scopes._USER_RECIPE_CREATE_]}
                                            element={<AddRecipe />}
                                        />
                                    }
                                ></Route>
                                <Route
                                    path="edit-recipe/:recipeId"
                                    element={
                                        <ProtectedRoute
                                            mustHaveOneOf={[Scopes._USER_RECIPE_UPDATE_]}
                                            element={<AddRecipe />}
                                        />
                                    }
                                ></Route>
                                <Route path="profiles/@:username" element={<Profile />} />
                                <Route path="settings" element={<ProtectedRoute element={<Settings />} />}></Route>
                                <Route path="recipes/:recipeId" element={<Recipe />} />
                                <Route path="recipes" element={<Recipes />} />
                                <Route path="401" element={<Unauthorized />} />
                                <Route path="403" element={<Forbidden />} />
                                <Route
                                    path="/admin"
                                    element={
                                        <ProtectedRoute
                                            mustHaveOneOf={[
                                                Scopes._ADMIN_FOOD_READ_,
                                                Scopes._ADMIN_USER_READ_,
                                                Scopes._ADMIN_ROLE_READ_,
                                            ]}
                                            element={<LayoutAdmin />}
                                        ></ProtectedRoute>
                                    }
                                >
                                    <Route path="" element={<Navigate to="dashboard" />} />
                                    <Route
                                        path="dashboard"
                                        element={
                                            <ProtectedRoute
                                                element={<AdminDashboard />}
                                                mustHaveOneOf={[
                                                    Scopes._ADMIN_FOOD_READ_,
                                                    Scopes._ADMIN_USER_READ_,
                                                    Scopes._ADMIN_ROLE_READ_,
                                                ]}
                                            />
                                        }
                                    />
                                    <Route
                                        path="foods/add-food"
                                        element={
                                            <ProtectedRoute
                                                element={<AdminFood />}
                                                mustHaveOneOf={[Scopes._ADMIN_FOOD_CREATE_]}
                                            />
                                        }
                                    />
                                    <Route
                                        path="foods/@:foodId"
                                        element={
                                            <ProtectedRoute
                                                element={<AdminFood />}
                                                mustHaveOneOf={[Scopes._ADMIN_FOOD_READ_]}
                                            />
                                        }
                                    />
                                    <Route
                                        path="foods"
                                        element={
                                            <ProtectedRoute
                                                element={<AdminFoods />}
                                                mustHaveOneOf={[Scopes._ADMIN_FOOD_READ_]}
                                            />
                                        }
                                    />
                                    <Route
                                        path="users/add-user"
                                        element={
                                            <ProtectedRoute
                                                element={<AdminUser />}
                                                mustHaveOneOf={[Scopes._ADMIN_USER_CREATE_]}
                                            />
                                        }
                                    />
                                    <Route
                                        path="users/@:userId"
                                        element={
                                            <ProtectedRoute
                                                element={<AdminUser />}
                                                mustHaveOneOf={[Scopes._ADMIN_USER_READ_]}
                                            />
                                        }
                                    />
                                    <Route
                                        path="users"
                                        element={
                                            <ProtectedRoute
                                                element={<AdminUsers />}
                                                mustHaveOneOf={[Scopes._ADMIN_USER_READ_]}
                                            />
                                        }
                                    />
                                    <Route
                                        path="roles/add-role"
                                        element={
                                            <ProtectedRoute
                                                element={<AdminRole />}
                                                mustHaveOneOf={[Scopes._ADMIN_ROLE_CREATE_]}
                                            />
                                        }
                                    />
                                    <Route
                                        path="roles/@:roleId"
                                        element={
                                            <ProtectedRoute
                                                element={<AdminRole />}
                                                mustHaveOneOf={[Scopes._ADMIN_ROLE_READ_]}
                                            />
                                        }
                                    />
                                    <Route
                                        path="roles"
                                        element={
                                            <ProtectedRoute
                                                element={<AdminRoles />}
                                                mustHaveOneOf={[Scopes._ADMIN_ROLE_READ_]}
                                            />
                                        }
                                    />
                                    <Route
                                        path="ingredient-units/add-ingredient-unit"
                                        element={
                                            <ProtectedRoute
                                                element={<AdminIngredientUnitPage />}
                                                mustHaveOneOf={[Scopes._ADMIN_INGREDIENT_UNIT_CREATE_]}
                                            />
                                        }
                                    />
                                    <Route
                                        path="ingredient-units/@:ingredientUnitId"
                                        element={
                                            <ProtectedRoute
                                                element={<AdminIngredientUnitPage />}
                                                mustHaveOneOf={[Scopes._ADMIN_INGREDIENT_UNIT_READ_]}
                                            />
                                        }
                                    />
                                    <Route
                                        path="ingredient-units"
                                        element={
                                            <ProtectedRoute
                                                element={<AdminIngredientUnitsPage />}
                                                mustHaveOneOf={[Scopes._ADMIN_INGREDIENT_UNIT_READ_]}
                                            />
                                        }
                                    />
                                    <Route
                                        path="recipes/@:recipeId"
                                        element={
                                            <ProtectedRoute
                                                element={<AdminRecipe />}
                                                mustHaveOneOf={[Scopes._ADMIN_RECIPE_READ_]}
                                            />
                                        }
                                    />
                                    <Route
                                        path="recipes"
                                        element={
                                            <ProtectedRoute
                                                element={<AdminRecipes />}
                                                mustHaveOneOf={[Scopes._ADMIN_RECIPE_READ_]}
                                            />
                                        }
                                    />
                                    <Route
                                        path="cache"
                                        element={
                                            <ProtectedRoute
                                                element={<AdminCache />}
                                                mustHaveOneOf={[Scopes._ADMIN_CACHE_READ_]}
                                            />
                                        }
                                    />
                                    <Route
                                        path="scopes/add-scope"
                                        element={
                                            <ProtectedRoute
                                                element={<AdminScope />}
                                                mustHaveOneOf={[Scopes._ADMIN_SCOPE_CREATE_]}
                                            />
                                        }
                                    />
                                    <Route
                                        path="scopes/@:scopeId"
                                        element={
                                            <ProtectedRoute
                                                element={<AdminScope />}
                                                mustHaveOneOf={[Scopes._ADMIN_SCOPE_READ_]}
                                            />
                                        }
                                    />
                                    <Route
                                        path="scopes"
                                        element={
                                            <ProtectedRoute
                                                element={<AdminScopes />}
                                                mustHaveOneOf={[Scopes._ADMIN_SCOPE_READ_]}
                                            />
                                        }
                                    />
                                </Route>
                                <Route path="*" element={<NotFound />} />
                            </Route>
                        </Routes>
                    </Suspense>
                </ErrorBoundaryComponent>
            </ScrollToTop>
        </Providers>
    );
};

export default App;
