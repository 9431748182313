import React, { useEffect, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTopComponent: React.FunctionComponent<PropsWithChildren<{ id?: string }>> = ({ children, id }) => {
    const location = useLocation();

    useEffect(() => {
        if (id) {
            const elt = document.getElementById(`${id}`);
            if (elt) {
                elt.scrollTo(0, 0);
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [location, id]);

    return <>{children}</>;
};

export default ScrollToTopComponent;
