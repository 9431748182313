import axios, { AxiosResponse } from 'axios';
import { Food } from '../store/Food/Food.type';
import serverService from './server.service';

export class FoodService {
    fetch(): Promise<AxiosResponse<[Food[], number]>> {
        return axios.get(`${serverService.getServer()}/foods`);
    }

    fetchById(foodId: string): Promise<AxiosResponse<Food>> {
        return axios.get(`${serverService.getServer()}/foods/${foodId}`);
    }

    create(food: Food): Promise<AxiosResponse<Food>> {
        return axios.post(`${serverService.getServer()}/foods`, food);
    }

    update(food: Food): Promise<AxiosResponse<Food>> {
        return axios.put(`${serverService.getServer()}/foods/${food.food_id}`, food);
    }

    delete(foodId: string): Promise<AxiosResponse<Food>> {
        return axios.delete(`${serverService.getServer()}/foods/${foodId}`);
    }
}

export default FoodService;
