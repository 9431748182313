import axios, { AxiosResponse } from 'axios';
import { Stock } from '../store/Stock/Stock.type';
import serverService from './server.service';

export class StockService {
    fetch(): Promise<AxiosResponse<[Stock[], number]>> {
        return axios.get(`${serverService.getServer()}/stocks`);
    }

    fetchById(stockId: string): Promise<AxiosResponse<Stock>> {
        return axios.get(`${serverService.getServer()}/stocks/${stockId}`);
    }

    create(stock: Stock): Promise<AxiosResponse<Stock>> {
        return axios.post(`${serverService.getServer()}/stocks`, stock);
    }

    update(stock: Stock): Promise<AxiosResponse<Stock>> {
        return axios.put(`${serverService.getServer()}/stocks/${stock.stock_id}`, stock);
    }

    delete(stockId: string): Promise<AxiosResponse<Stock>> {
        return axios.delete(`${serverService.getServer()}/stocks/${stockId}`);
    }
}

export default StockService;
