import axios, { AxiosResponse } from 'axios';
import { Scope } from '../store/Scope/Scope.type';
import serverService from './server.service';

export class ScopeService {
    fetch(): Promise<AxiosResponse<[Scope[], number]>> {
        return axios.get(`${serverService.getServer()}/scopes`);
    }

    fetchById(scopeId: string): Promise<AxiosResponse<Scope>> {
        return axios.get(`${serverService.getServer()}/scopes/${scopeId}`);
    }

    create(scope: Scope): Promise<AxiosResponse<Scope>> {
        return axios.post(`${serverService.getServer()}/scopes`, scope);
    }

    update(scope: Scope): Promise<AxiosResponse<Scope>> {
        return axios.put(`${serverService.getServer()}/scopes/${scope.scope_id}`, scope);
    }

    delete(scopeId: string): Promise<AxiosResponse<Scope>> {
        return axios.delete(`${serverService.getServer()}/scopes/${scopeId}`);
    }
}

export default ScopeService;
