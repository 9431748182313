import React from 'react';

interface GuardProps {
    FallBackComponent?: React.ReactNode;
    isAuthorized: boolean;
}

export const Guard: React.FunctionComponent<GuardProps> = (props) => {
    const { isAuthorized, children, FallBackComponent } = props;
    return <>{isAuthorized ? children : FallBackComponent}</>;
};

Guard.defaultProps = {
    FallBackComponent: null,
};
