import classNames from 'classnames';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from './AlertProvider';

const VARIANTS = {
    info: {
        base: 'bg-blue-200',
        color: 'text-blue-600',
        icon: 'info',
        nameId: 'alert.info',
    },
    error: {
        base: 'bg-red-200',
        color: 'text-red-600',
        icon: 'cancel',
        nameId: 'alert.error',
    },
    warning: {
        base: 'bg-yellow-200',
        color: 'text-yellow-600',
        icon: 'report_problem',
        nameId: 'alert.warning',
    },
    success: {
        base: 'bg-green-200',
        color: 'text-green-600',
        icon: 'done',
        nameId: 'alert.success',
    },
};

export type AlertMessageType = {
    id: string;
    lifetime?: number;
    variant?: keyof typeof VARIANTS | undefined;
    onRemove?: (id: string) => void;
} & Alert;

export default function AlertMessage({ id, header, message, lifetime, onRemove, icon, type }: AlertMessageType) {
    const variant = type
        ? VARIANTS[type]
        : {
              base: 'bg-gray-200',
              color: 'text-gray-600',
              icon: icon,
              nameId: header,
          };

    useEffect(() => {
        if (lifetime && onRemove) {
            setTimeout(() => {
                onRemove(id);
            }, lifetime);
        }
    }, [lifetime, id, onRemove]);

    return (
        <div className={classNames('w-full p-2', variant.base, variant.color)}>
            <div className=" ml-3 mr-2 flex justify-between items-center">
                <div className="flex">
                    {variant.icon && (
                        <div className="flex items-center h-12 w-12 text-xl mr-2">
                            <span className={classNames('material-icons', variant.color)}>{variant.icon}</span>
                        </div>
                    )}
                    <div className="flex flex-col flex-no-wrap px-1 w-full justify-center">
                        <div className="font-bold leading-3">
                            <FormattedMessage id={variant.nameId} data-testid={variant.nameId} />
                        </div>
                        <p className="break-all text-sm">{message}</p>
                    </div>
                </div>
                <div className="justify-center items-center flex">
                    <button onClick={() => onRemove && onRemove(id)} className="text-lg leading-3">
                        <span className="material-icons">clear</span>
                    </button>
                </div>
            </div>
        </div>
    );
}
