import React, { PropsWithChildren } from 'react';
import AuthProvider from './auth.provider';
import I18nProvider from './i18n.provider';
import RouterProvider from './router.provider';
import StoreProvider from './store.provider';

const Providers: React.FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    return (
        <StoreProvider>
            <I18nProvider>
                <AuthProvider>
                    <RouterProvider>{children}</RouterProvider>
                </AuthProvider>
            </I18nProvider>
        </StoreProvider>
    );
};

export default Providers;
