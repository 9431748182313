import { action, Action, thunk, Thunk } from 'easy-peasy';
import { Injections } from '../injections';

import { IngredientUnit } from './IngredientUnit.type';

export interface IngredientUnitModel {
    ingredientUntis: IngredientUnit[];
    count: number | undefined;
    lastFetchedTime: Date | undefined;
    setIngredientUnits: Action<IngredientUnitModel, IngredientUnit[]>;
    setCount: Action<IngredientUnitModel, number>;
    setLastFetchedTime: Action<IngredientUnitModel, void>;
    fetch: Thunk<IngredientUnitModel, void, Injections>;
    fetchById: Thunk<IngredientUnitModel, string, Injections>;
    create: Thunk<IngredientUnitModel, IngredientUnit, Injections>;
    update: Thunk<IngredientUnitModel, IngredientUnit, Injections>;
    remove: Thunk<IngredientUnitModel, IngredientUnit, Injections>;
}

export const ingredientUnitModel: IngredientUnitModel = {
    ingredientUntis: [],
    count: undefined,
    lastFetchedTime: undefined,
    setIngredientUnits: action((state, payload: IngredientUnit[]) => {
        state.ingredientUntis = payload;
    }),
    setCount: action((state, payload: number) => {
        state.count = payload;
    }),
    setLastFetchedTime: action((state, _payload) => {
        state.lastFetchedTime = new Date();
    }),
    fetch: thunk(async (actions, _payload, { injections }) => {
        try {
            const { ingredientUnitService } = injections;
            const ingredientUnits = await ingredientUnitService.fetch();
            actions.setCount(ingredientUnits.data[1]);
            actions.setIngredientUnits(ingredientUnits.data[0]);
            actions.setLastFetchedTime();
        } catch (error) {
            console.error(error);
        }
    }),
    fetchById: thunk(async (actions, payload, { injections }) => {
        try {
            const { ingredientUnitService } = injections;
            const food = await ingredientUnitService.fetchById(payload);
            return food.data;
        } catch (error) {
            console.error(error);
        }
    }),
    create: thunk(async (actions, payload, { injections }) => {
        try {
            const { ingredientUnitService } = injections;
            const ingredientUnit = await ingredientUnitService.create(payload);
            await actions.fetch();
            return ingredientUnit.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
    update: thunk(async (actions, payload, { injections }) => {
        try {
            const { ingredientUnitService } = injections;
            const ingredientUnit = await ingredientUnitService.update(payload);
            await actions.fetch();
            return ingredientUnit.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
    remove: thunk(async (actions, payload, { injections }) => {
        try {
            const { ingredientUnitService } = injections;
            const ingredientUnit = await ingredientUnitService.remove(payload);
            await actions.fetch();
            return ingredientUnit.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
};

export default ingredientUnitModel;
