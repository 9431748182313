import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { ProtectedComponent } from '../../guards/role-guards';

import styles from './SidePanel.module.scss';

export interface SidePanelItem {
    icon: string;
    link: string;
    exact: boolean;
    mustHaveOneOf?: string[];
}

interface SidePanelProps {
    items: SidePanelItem[];
}

const SidePanelComponent: FC<SidePanelProps> = (props) => {
    const { items } = props;
    return (
        <div className="h-full border-r bg-white">
            <aside className={`${styles.sidePanelWrapper}`}>
                <div className={styles.sidePanelContainer}>
                    <div className={styles.sidePanelMenu}>
                        {items.map((item, index) => (
                            <ProtectedComponent key={`sidepanel-item-${index}`} mustHaveOneOf={item.mustHaveOneOf}>
                                <NavLink
                                    id={`sidepanel-item-${index}`}
                                    to={item.link}
                                    className={({ isActive }) =>
                                        `${isActive ? 'bg-green-200 text-green-600' : 'text-gray-500'} w-full h-full `
                                    }
                                >
                                    {({ isActive }) => (
                                        <div
                                            className={`flex items-center justify-center w-14 h-14 hover:text-green-600 hover:bg-green-200 transition-all ${
                                                isActive ? 'bg-green-100' : ''
                                            }`}
                                        >
                                            <span className="material-icons">{item.icon}</span>
                                        </div>
                                    )}
                                </NavLink>
                            </ProtectedComponent>
                        ))}
                    </div>
                </div>
            </aside>
        </div>
    );
};

export default SidePanelComponent;
