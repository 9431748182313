import axios, { AxiosResponse } from 'axios';
import { Recipe } from '../store/Recipe/Recipe.type';
import serverService from './server.service';

export class RecipeService {
    fetch(limit: number = 10, page: number = 1): Promise<AxiosResponse<[Recipe[], number]>> {
        const skip = limit * (page - 1);
        return axios.get(`${serverService.getServer()}/recipes?limit=${limit}&skip=${skip}`);
    }

    fetchById(recipeId: string): Promise<AxiosResponse<Recipe>> {
        return axios.get(`${serverService.getServer()}/recipes/${recipeId}`);
    }

    create(recipe: Recipe): Promise<AxiosResponse<Recipe>> {
        return axios.post(`${serverService.getServer()}/recipes`, recipe);
    }

    update(recipe: Recipe): Promise<AxiosResponse<Recipe[]>> {
        return axios.put(`${serverService.getServer()}/recipes/${recipe.recipe_id}`, recipe);
    }

    remove(recipe: Recipe): Promise<AxiosResponse<Recipe[]>> {
        return axios.delete(`${serverService.getServer()}/recipes/${recipe.recipe_id}`);
    }
}

export default RecipeService;
