import { FC, Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useStoreActions, useStoreState } from '../../store/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAlert } from '../../components/Alert/AlertProvider';
import { Dialog, Transition } from '@headlessui/react';
import { ProtectedComponent } from '../../guards/role-guards';
import { IngredientUnit } from '../../store/IngredientUnit/IngredientUnit.type';
import { Scopes } from '../../store/Scope/Scope.type';

const RETURN_INGREDIENT_UNITS = '/admin/ingredient-units';

const initialIngredientUnit: IngredientUnit = {
    ingredient_unit_id: uuidv4(),
    lib: '',
    header: '',
    created_at: new Date(),
    updated_at: new Date(),
};

const AdminIngredientUnitPage: FC = () => {
    const [ingredientUnit, setIngredientUnit] = useState<IngredientUnit>(initialIngredientUnit);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const { ingredientUntis } = useStoreState(({ ingredientUnit }) => ingredientUnit);
    const { fetchById, create, update, remove } = useStoreActions(({ ingredientUnit }) => ingredientUnit);
    const [loading, setLoading] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState(false);

    const { ingredientUnitId } = useParams<'ingredientUnitId'>();
    const intl = useIntl();
    const alert = useAlert();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchIngredientUnit = async (id: string) => {
            const fetchedIngredientUnit = await fetchById(id);
            if (fetchedIngredientUnit?.ingredient_unit_id) {
                setIngredientUnit(fetchedIngredientUnit);
                setIsEdit(true);
            } else {
                console.log('not found');
            }
        };

        if (ingredientUnitId) {
            if (ingredientUntis.length > 0) {
                const retrievedIngredientUnit = ingredientUntis.filter(
                    (i) => i.ingredient_unit_id === ingredientUnitId,
                );
                if (retrievedIngredientUnit && retrievedIngredientUnit.length > 0) {
                    setIngredientUnit(retrievedIngredientUnit[0]);
                    setIsEdit(true);
                } else {
                    console.log('not found');
                }
            } else {
                fetchIngredientUnit(ingredientUnitId);
            }
        }
    }, [fetchById, ingredientUnitId, ingredientUntis]);

    const createIngredientUnit = async () => {
        setLoading(true);
        const i = await create(ingredientUnit);
        setLoading(false);
        if (i) {
            alert?.pushSuccess(intl.formatMessage({ id: 'pages.adminIngredientUnit.created.success' }));
            navigate({ pathname: RETURN_INGREDIENT_UNITS });
        } else {
            alert?.pushError(intl.formatMessage({ id: 'pages.adminIngredientUnit.created.error' }));
        }
    };

    const updateIngredientUnit = async () => {
        setLoading(true);
        const i = await update(ingredientUnit);
        setLoading(false);
        if (i) {
            alert?.pushSuccess(intl.formatMessage({ id: 'pages.adminIngredientUnit.updated.success' }));
            navigate({ pathname: RETURN_INGREDIENT_UNITS });
        } else {
            alert?.pushError(intl.formatMessage({ id: 'pages.adminIngredientUnit.updated.error' }));
        }
    };

    const removeRole = async () => {
        setIsOpen(false);
        setLoading(true);
        const i = await remove(ingredientUnit);
        setLoading(false);
        if (i) {
            alert?.pushSuccess(intl.formatMessage({ id: 'pages.adminIngredientUnit.removed.success' }));
            navigate({ pathname: RETURN_INGREDIENT_UNITS });
        } else {
            alert?.pushError(intl.formatMessage({ id: 'pages.adminIngredientUnit.removed.error' }));
        }
    };

    return (
        <div className="w-full">
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={() => setIsOpen(false)}>
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-30 transition-all" />
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="inline-block h-screen align-middle" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
                                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                    <FormattedMessage
                                        id="pages.adminIngredientUnit.modal.delete"
                                        data-testid="pages.adminIngredientUnit.modal.delete"
                                    />
                                </Dialog.Title>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        <FormattedMessage
                                            id="pages.adminIngredientUnit.modal.desc"
                                            data-testid="pages.adminIngredientUnit.modal.desc"
                                            values={{ label: ingredientUnit.lib }}
                                        />
                                    </p>
                                </div>

                                <div className="mt-4 flex justify-end space-x-2">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-200 border border-transparent rounded-md hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        <FormattedMessage
                                            id="pages.adminIngredientUnit.modal.cancel"
                                            data-testid="pages.adminIngredientUnit.modal.cancel"
                                        />
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 text-sm font-semibold rounded-md text-red-500 hover:text-red-700 transition-all hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                        onClick={() => removeRole()}
                                    >
                                        <FormattedMessage
                                            id="pages.adminIngredientUnit.modal.confirm"
                                            data-testid="pages.adminIngredientUnit.modal.confirm"
                                        />
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
            <button
                type="submit"
                className="w-auto flex justify-center py-1 px-2 border border-transparent text-sm font-semibold rounded-md text-gray-500 hover:bg-gray-200"
                onClick={() => navigate(-1)}
            >
                <div className="mr-2">
                    <span className="material-icons text-sm">arrow_back</span>
                </div>
                <div>
                    <FormattedMessage
                        id="pages.adminIngredientUnit.cancel"
                        data-testid="pages.adminIngredientUnit.cancel"
                    />
                </div>
            </button>
            <div className="xl:px-28 lg:px-16 md:px-5 px-2 mt-5">
                <div className="font-bold text-3xl text-gray-600">{ingredientUnit.lib}</div>
                <div className="p-4 space-y-6">
                    <div className="space-y-3 pb-4 border-b border-gray-300">
                        <div className="block text-md font-bold text-gray-700">
                            <FormattedMessage id="shared.description" data-testid="shared.description" />
                        </div>
                        <div className="col-span-6 sm:col-span-3 px-5">
                            <label htmlFor="name" className="block text-sm font-semibold text-gray-700">
                                <FormattedMessage
                                    id="pages.adminIngredientUnit.name"
                                    data-testid="pages.adminIngredientUnit.name"
                                />
                            </label>
                            <div className="mb-3 pt-0">
                                <input
                                    defaultValue={ingredientUnit.lib}
                                    id="name"
                                    name="name"
                                    type="text"
                                    placeholder={`${intl.formatMessage({ id: 'pages.adminIngredientUnit.name' })}`}
                                    onBlur={(e) => setIngredientUnit({ ...ingredientUnit, lib: e.currentTarget.value })}
                                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="space-y-3">
                        <div className="block text-md font-bold text-gray-700">
                            <FormattedMessage id="shared.technical" data-testid="shared.technical" />
                        </div>
                        <div className="col-span-6 sm:col-span-3 px-5">
                            <label htmlFor="createdAt" className="block text-sm font-medium text-gray-700">
                                <FormattedMessage id="shared.createdAt" data-testid="shared.createdAt" />
                            </label>
                            <div className="mb-3 pt-0">
                                <div className="appearance-none relative bg-gray-200 block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm">
                                    {`${intl.formatDate(ingredientUnit.created_at)} ${intl.formatTime(
                                        ingredientUnit.created_at,
                                    )}`}
                                </div>
                            </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3 px-5">
                            <label htmlFor="updatedAt" className="block text-sm font-medium text-gray-700">
                                <FormattedMessage id="shared.updatedAt" data-testid="shared.updatedAt" />
                            </label>
                            <div className="mb-3 pt-0">
                                <div className="appearance-none relative bg-gray-200 block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm">
                                    {`${intl.formatDate(ingredientUnit.updated_at)} ${intl.formatTime(
                                        ingredientUnit.updated_at,
                                    )}`}
                                </div>
                            </div>
                        </div>
                    </div>

                    {isEdit ? (
                        <div className="mt-3 flex justify-between">
                            <div>
                                <ProtectedComponent mustHaveOneOf={[Scopes._ADMIN_INGREDIENT_UNIT_DELETE_]}>
                                    <button
                                        disabled={loading}
                                        type="submit"
                                        className="w-auto flex items-center justify-center py-2 px-4 border border-transparent text-sm font-bold uppercase rounded-md text-red-500 hover:text-red-700 transition-all hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                                        onClick={() => setIsOpen(true)}
                                    >
                                        <span className="material-icons mr-3">delete_outline</span>
                                        <FormattedMessage
                                            id="pages.adminIngredientUnit.delete"
                                            data-testid="pages.adminIngredientUnit.delete"
                                        />
                                    </button>
                                </ProtectedComponent>
                            </div>
                            <div>
                                <ProtectedComponent mustHaveOneOf={[Scopes._ADMIN_INGREDIENT_UNIT_UPDATE_]}>
                                    <button
                                        disabled={loading}
                                        type="submit"
                                        className="w-auto flex items-center justify-center py-2 px-4 border border-transparent text-sm font-bold uppercase rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
                                        onClick={() => updateIngredientUnit()}
                                    >
                                        <FormattedMessage
                                            id="pages.adminIngredientUnit.update"
                                            data-testid="pages.adminIngredientUnit.update"
                                        />
                                    </button>
                                </ProtectedComponent>
                            </div>
                        </div>
                    ) : (
                        <div className="mt-3 flex justify-end">
                            <ProtectedComponent mustHaveOneOf={[Scopes._ADMIN_INGREDIENT_UNIT_CREATE_]}>
                                <button
                                    disabled={loading}
                                    type="submit"
                                    className="w-auto flex items-center justify-center py-2 px-4 border border-transparent text-sm font-bold uppercase rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
                                    onClick={() => createIngredientUnit()}
                                >
                                    <FormattedMessage
                                        id="pages.adminIngredientUnit.create"
                                        data-testid="pages.adminIngredientUnit.create"
                                    />
                                </button>
                            </ProtectedComponent>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdminIngredientUnitPage;
