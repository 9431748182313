import { action, Action, thunk, Thunk } from 'easy-peasy';
import { Injections } from '../injections';

import { Recipe } from './Recipe.type';

export interface RecipeModel {
    recipes: Recipe[];
    count: number | undefined;
    lastFetchedTime: Date | undefined;
    setRecipes: Action<RecipeModel, Recipe[]>;
    setCount: Action<RecipeModel, number>;
    setLastFetchedTime: Action<RecipeModel, void>;
    fetch: Thunk<RecipeModel, { limit: number; page: number }, Injections>;
    fetchById: Thunk<RecipeModel, string, Injections>;
    create: Thunk<RecipeModel, Recipe, Injections>;
    update: Thunk<RecipeModel, Recipe, Injections>;
    remove: Thunk<RecipeModel, Recipe, Injections>;
}

export const recipeModel: RecipeModel = {
    recipes: [],
    count: undefined,
    lastFetchedTime: undefined,
    setRecipes: action((state, payload: Recipe[]) => {
        state.recipes = payload;
    }),
    setCount: action((state, payload: number) => {
        state.count = payload;
    }),
    setLastFetchedTime: action((state, _payload) => {
        state.lastFetchedTime = new Date();
    }),
    fetch: thunk(async (actions, payload, { injections }) => {
        try {
            const { recipeService } = injections;
            const recipes = await recipeService.fetch(payload.limit, payload.page);
            actions.setCount(recipes.data[1]);
            actions.setRecipes(recipes.data[0]);
            actions.setLastFetchedTime();
        } catch (error) {
            console.error(error);
        }
    }),
    create: thunk(async (actions, payload, { injections }) => {
        try {
            const { recipeService } = injections;
            const recipe = await recipeService.create(payload);
            await actions.fetch({ limit: 100, page: 1 });
            return recipe.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
    update: thunk(async (actions, payload, { injections }) => {
        try {
            const { recipeService } = injections;
            const recipe = await recipeService.update(payload);
            await actions.fetch({ limit: 100, page: 1 });
            return recipe.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
    remove: thunk(async (actions, payload, { injections }) => {
        try {
            const { recipeService } = injections;
            const recipe = await recipeService.remove(payload);
            await actions.fetch({ limit: 100, page: 1 });
            return recipe.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
    fetchById: thunk(async (actions, payload, { injections }) => {
        try {
            const { recipeService } = injections;
            const recipe = await recipeService.fetchById(payload);
            return recipe.data;
        } catch (error) {
            console.error(error);
        }
    }),
};

export default recipeModel;
