import { action, Action, thunk, Thunk } from 'easy-peasy';
import { Injections } from '../injections';

import { Status, Userinfo } from './Userinfo.type';

export interface UserinfoModel {
    // User
    user: Userinfo;
    access_token: string;
    status: Status;
    loading: boolean;

    // User actions
    setStatus: Action<UserinfoModel, Status>;
    set: Action<UserinfoModel, Userinfo>;
    setAccessToken: Action<UserinfoModel, string>;
    reset: Action<UserinfoModel, void>;
    fetch: Thunk<UserinfoModel, void, Injections>;
    signIn: Thunk<UserinfoModel, { username: string; password: string }, Injections>;
    signOut: Thunk<UserinfoModel, void, Injections>;
}

export const userinfoModel: UserinfoModel = {
    // User
    user: Object.create(null) as Userinfo,
    status: 'NOT_AUTHENTICATED',
    access_token: '',
    loading: false,

    setStatus: action((state, payload: Status) => {
        state.status = payload;
    }),
    set: action((state, payload) => {
        state.user = payload;
    }),
    setAccessToken: action((state, payload) => {
        state.access_token = payload;
    }),
    reset: action((state) => {
        state.user = Object.create(null) as Userinfo;
        state.access_token = '';
    }),

    // User Thunks
    fetch: thunk(async (actions, _payload, { injections }) => {
        try {
            const { userService } = injections;
            const userInfo = await userService.fetchInfo();
            actions.set(userInfo);
            actions.setStatus('AUTHENTICATION_SUCCESSFUL');
        } catch (error) {
            console.error(error);
            actions.reset();
            actions.setStatus('AUTHENTICATION_FORBIDDEN');
        }
    }),
    signIn: thunk(async (actions, payload, { injections }) => {
        try {
            const { userService } = injections;
            const auth = await userService.signIn(payload.username, payload.password);
            await actions.setAccessToken(auth.access_token);
            await actions.setStatus('AUTHENTICATION_SUCCESSFUL');
            return true;
        } catch (error) {
            console.error(error);
            actions.reset();
            actions.setStatus('AUTHENTICATION_FORBIDDEN');
            return false;
        }
    }),
    signOut: thunk(async (actions, _payload, { injections }) => {
        try {
            const { userService } = injections;
            await userService.signOut();
            await actions.reset();
            actions.setStatus('NOT_AUTHENTICATED');
            return true;
        } catch (error) {
            console.error(error);
            actions.reset();
            actions.setStatus('AUTHENTICATION_FORBIDDEN');
            return false;
        }
    }),
};

export default userinfoModel;
