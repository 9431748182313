import React, { FC, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { Menu, Transition } from '@headlessui/react';

import { useStoreState, useStoreActions } from '../../store/hooks';

const Account: FC<{}> = (props) => {
    const { user } = useStoreState(({ userinfo }) => userinfo);
    const { signOut } = useStoreActions(({ userinfo }) => userinfo);

    const onSignOut = async () => {
        if (await signOut()) {
            console.log('ciao');
        }
    };

    return (
        <>
            {user?.user_id ? (
                <div className="mr-3">
                    <Menu as="div" className="ml-3 relative">
                        {({ open }) => (
                            <>
                                <div>
                                    <Menu.Button className="bg-green-500 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-800 focus:ring-white">
                                        <span className="sr-only">
                                            <FormattedMessage
                                                id="components.account.openMenu"
                                                data-testid="components.account.openMenu"
                                            />
                                        </span>
                                        <div className="h-8 w-8 rounded-full text-white flex items-center justify-center text-lg uppercase font-bold">
                                            <span>{user.username.charAt(0)}</span>
                                        </div>
                                    </Menu.Button>
                                </div>
                                <Transition
                                    show={open}
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        static
                                        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                                    >
                                        <Menu.Item>
                                            {({ active }) => (
                                                <NavLink
                                                    to={`/profiles/@${user.username}`}
                                                    className={classnames(
                                                        active ? 'bg-gray-100' : '',
                                                        'block px-4 py-2 text-sm text-gray-700 capitalize',
                                                    )}
                                                >
                                                    @{user.username}
                                                </NavLink>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <NavLink
                                                    to="/settings"
                                                    className={classnames(
                                                        active ? 'bg-gray-100' : '',
                                                        'block px-4 py-2 text-sm text-gray-700',
                                                    )}
                                                >
                                                    <FormattedMessage
                                                        id="components.account.settings"
                                                        data-testid="components.account.settings"
                                                    />
                                                </NavLink>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    onClick={() => onSignOut()}
                                                    className={classnames(
                                                        active ? 'bg-gray-100' : '',
                                                        'block px-4 py-2 text-sm text-gray-700 border-t border-gray-200 w-full',
                                                    )}
                                                >
                                                    <FormattedMessage
                                                        id="components.account.signout"
                                                        data-testid="components.account.signout"
                                                    />
                                                </button>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </>
                        )}
                    </Menu>
                </div>
            ) : (
                <NavLink
                    className="text-green-600 lg:hover:text-white
                            bg-transparent lg:hover:bg-green-600
                            lg:border lg:border-solid lg:border-green-600
                            lg:px-4 lg:py-2 mr-1 mb-1
                            flex
                            font-bold uppercase
                            rounded outline-none focus:outline-none
                            ease-linear transition-all duration-150"
                    type="button"
                    to="/sign-in"
                >
                    <span className="material-icons lg:mr-2 align-middle">account_circle</span>{' '}
                    <span className="hidden lg:block align-middle">
                        <FormattedMessage id="components.account.signin" data-testid="components.account.signin" />
                    </span>
                </NavLink>
            )}
        </>
    );
};

export default Account;
