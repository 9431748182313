import { useContext } from 'react';
import AlertMessage from './AlertMessage';
import { AlertContext } from './AlertProvider';

export default function AlertContainer() {
    const context = useContext(AlertContext);

    function handleRemove(id: string) {
        context?.remove(id);
    }

    return (
        <div className="">
            {context?.data.map((alert) => {
                return (
                    <div key={alert.id}>
                        <AlertMessage
                            id={alert.id}
                            message={alert.message}
                            type={alert.type}
                            header={alert.header}
                            icon={alert.icon}
                            onRemove={handleRemove}
                            lifetime={alert.lifetime}
                        />
                    </div>
                );
            })}
        </div>
    );
}
