export interface Scope {
    scope_id: string;
    label: string;
    created_at: Date;
    updated_at: Date;
}

export enum Scopes {
    _ADMIN_CACHE_READ_ = 'admin.cache.read',
    _ADMIN_CACHE_RESET_ = 'admin.cache.reset',
    _ADMIN_INIT_DELETE_ = 'admin.init.delete',
    _ADMIN_INIT_FOOD_DELETE_ = 'admin.init.food.delete',
    _ADMIN_INIT_FOOD_CREATE_ = 'admin.init.food.create',
    _ADMIN_RECIPE_CREATE_ = 'admin.recipe.create',
    _ADMIN_RECIPE_UPDATE_ = 'admin.recipe.update',
    _ADMIN_RECIPE_DELETE_ = 'admin.recipe.delete',
    _ADMIN_RECIPE_READ_ = 'admin.recipe.read',
    _ADMIN_FOOD_CREATE_ = 'admin.food.create',
    _ADMIN_FOOD_UPDATE_ = 'admin.food.update',
    _ADMIN_FOOD_DELETE_ = 'admin.food.delete',
    _ADMIN_FOOD_READ_ = 'admin.food.read',
    _ADMIN_UPLOAD_CREATE_ = 'admin.upload.create',
    _ADMIN_UPLOAD_UPDATE_ = 'admin.upload.update',
    _ADMIN_UPLOAD_DELETE_ = 'admin.upload.delete',
    _ADMIN_UPLOAD_READ_ = 'admin.upload.read',
    _ADMIN_USER_CREATE_ = 'admin.user.create',
    _ADMIN_USER_UPDATE_ = 'admin.user.update',
    _ADMIN_USER_DELETE_ = 'admin.user.delete',
    _ADMIN_USER_READ_ = 'admin.user.read',
    _ADMIN_SCOPE_CREATE_ = 'admin.scope.create',
    _ADMIN_SCOPE_UPDATE_ = 'admin.scope.update',
    _ADMIN_SCOPE_DELETE_ = 'admin.scope.delete',
    _ADMIN_SCOPE_READ_ = 'admin.scope.read',
    _ADMIN_ROLE_CREATE_ = 'admin.role.create',
    _ADMIN_ROLE_UPDATE_ = 'admin.role.update',
    _ADMIN_ROLE_DELETE_ = 'admin.role.delete',
    _ADMIN_ROLE_READ_ = 'admin.role.read',
    _ADMIN_STEP_CREATE_ = 'admin.step.create',
    _ADMIN_STEP_UPDATE_ = 'admin.step.update',
    _ADMIN_STEP_DELETE_ = 'admin.step.delete',
    _ADMIN_STEP_READ_ = 'admin.step.read',
    _ADMIN_STOCK_CREATE_ = 'admin.stock.create',
    _ADMIN_STOCK_UPDATE_ = 'admin.stock.update',
    _ADMIN_STOCK_DELETE_ = 'admin.stock.delete',
    _ADMIN_STOCK_READ_ = 'admin.stock.read',
    _ADMIN_TOKEN_CREATE_ = 'admin.token.create',
    _ADMIN_TOKEN_UPDATE_ = 'admin.token.update',
    _ADMIN_TOKEN_DELETE_ = 'admin.token.delete',
    _ADMIN_TOKEN_READ_ = 'admin.token.read',
    _ADMIN_USTENSIL_CREATE_ = 'admin.ustensil.create',
    _ADMIN_USTENSIL_UPDATE_ = 'admin.ustensil.update',
    _ADMIN_USTENSIL_DELETE_ = 'admin.ustensil.delete',
    _ADMIN_USTENSIL_READ_ = 'admin.ustensil.read',
    _ADMIN_INGREDIENT_CREATE_ = 'admin.ingredient.create',
    _ADMIN_INGREDIENT_UPDATE_ = 'admin.ingredient.update',
    _ADMIN_INGREDIENT_DELETE_ = 'admin.ingredient.delete',
    _ADMIN_INGREDIENT_READ_ = 'admin.ingredient.read',
    _ADMIN_INGREDIENT_UNIT_CREATE_ = 'admin.ingredient.unit.create',
    _ADMIN_INGREDIENT_UNIT_UPDATE_ = 'admin.ingredient.unit.update',
    _ADMIN_INGREDIENT_UNIT_DELETE_ = 'admin.ingredient.unit.delete',
    _ADMIN_INGREDIENT_UNIT_READ_ = 'admin.ingredient.unit.read',
    _USER_RECIPE_CREATE_ = 'user.recipe.create',
    _USER_RECIPE_UPDATE_ = 'user.recipe.update',
    _USER_RECIPE_DELETE_ = 'user.recipe.delete',
    _USER_RECIPE_READ_ = 'user.recipe.read',
    _USER_STOCK_CREATE_ = 'user.stock.create',
    _USER_STOCK_UPDATE_ = 'user.stock.update',
    _USER_STOCK_DELETE_ = 'user.stock.delete',
    _USER_STOCK_READ_ = 'user.stock.read',
    _USER_FOOD_READ_ = 'user.food.read',
    _USER_INGREDIENT_UNIT_READ_ = 'user.ingredient.unit.read',
    _USER_USER_UPDATE_ = 'user.user.update',
    _USER_USER_DELETE_ = 'user.user.delete',
}
