import React from 'react';

import styles from './Error.module.scss';

interface ErrorProps {
    // TODO
}

const ErrorComponent: React.FunctionComponent<ErrorProps> = (props) => {
    return <div className={styles.root}>Error</div>;
};

export default ErrorComponent;
