import { FC, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from '../../store/hooks';
import { SemipolarLoading } from 'react-loadingg';

import styles from './AdminIngredientUnits.module.scss';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { ProtectedComponent } from '../../guards/role-guards';
import { Scopes } from '../../store/Scope/Scope.type';

const CREATE_PATH = '/admin/ingredient-units/add-ingredient-unit';

const AdminIngredientUnitsPage: FC = () => {
    const { ingredientUntis, count } = useStoreState(({ ingredientUnit }) => ingredientUnit);
    const { fetch } = useStoreActions(({ ingredientUnit }) => ingredientUnit);
    const [searchIngredientUnit, setSearchIngredientUnit] = useState<string>('');

    const intl = useIntl();

    useEffect(() => {
        if (count === undefined) {
            fetch();
        }
    }, [count, fetch]);

    return count !== undefined ? (
        <div className={styles.root}>
            <div className="w-full">
                <div className="bg-gray-100 p-5">
                    <div className="flex justify-between">
                        <div className="font-bold text-3xl text-gray-600">
                            <FormattedMessage
                                id="pages.adminIngredientUnits.search"
                                data-testid="pages.adminIngredientUnits.search"
                            />
                        </div>
                        <ProtectedComponent mustHaveOneOf={[Scopes._ADMIN_INGREDIENT_UNIT_CREATE_]}>
                            <NavLink
                                className="text-green-600 lg:hover:text-white
                            bg-transparent lg:hover:bg-green-600
                            lg:border lg:border-solid lg:border-green-600
                            lg:px-4 lg:py-2 mr-1 mb-1
                            flex
                            items-center
                            font-bold uppercase
                            rounded outline-none focus:outline-none
                            ease-linear transition-all duration-150"
                                to={`${CREATE_PATH}`}
                            >
                                <span className="material-icons lg:mr-2">add</span>{' '}
                                <span className="hidden lg:block">
                                    <FormattedMessage
                                        id="pages.adminIngredientUnits.create"
                                        data-testid="pages.adminIngredientUnits.create"
                                    />
                                </span>
                            </NavLink>
                        </ProtectedComponent>
                    </div>
                    <div className="mt-1 relative rounded-md flex">
                        <input
                            type="text"
                            className="block w-full py-2 pl-2 pr-3 sm:text-sm focus:ring-green-500 focus:border-green-500 border border-gray-300 rounded-md"
                            placeholder={intl.formatMessage({
                                id: 'pages.adminIngredientUnits.placeholder.roles',
                            })}
                            value={searchIngredientUnit}
                            onChange={(e) => setSearchIngredientUnit(e.currentTarget.value)}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap">
                    {ingredientUntis
                        .sort((i1, i2) => i1.lib.localeCompare(i2.lib))
                        .filter((i) => i.lib.toUpperCase().indexOf(searchIngredientUnit.toUpperCase()) >= 0)
                        .map((ingredientUnit) => (
                            <NavLink
                                className="xl:w-1/4 2xl:w-1/5 lg:w-1/3 md:w-1/2 w-full p-2"
                                key={`admin-ingredient-units-map-${ingredientUnit.ingredient_unit_id}`}
                                to={`/admin/ingredient-units/@${ingredientUnit.ingredient_unit_id}`}
                            >
                                <div className="w-full shadow-md hover:shadow-lg rounded-md bg-white group wrapper transition-all p-4">
                                    <div className="flex items-center">
                                        <div className="w-full">
                                            <div className="flex justify-between">
                                                <div className="group-hover:text-green-600 transition-all font-semibold text-2xl">
                                                    {ingredientUnit.lib}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        ))}
                </div>
            </div>
        </div>
    ) : (
        <SemipolarLoading size="large" color="#10b981" />
    );
};

export default AdminIngredientUnitsPage;
