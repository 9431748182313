const en = {
    shared: {
        createdBy: 'Created by',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        cancel: 'Cancel',
        new: 'New',
        veggie: 'Veggie',
        expired: 'Expired',
        shortDate: 'Short date',
        revoked: 'Revoked',
        description: 'Description',
        technical: 'Technical',
        seasonal: 'Seasonal',
        notSeasonel: 'Not in season',
        noResult: 'No result',
        storageArea: {
            cupboard: 'Cupboard',
            fridge: 'Fridge',
            freezer: 'Freezer',
        },
        navigation: {
            next: 'Next',
            previous: 'Previous',
        },
        upload: {
            error: 'Error sending image. Is it really an image?',
        },
        months: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
        },
        foodType: {
            fruit: 'Fruit',
            vegetable: 'Vegetable',
            meat: 'Meat',
            fish: 'Fish',
            egg: 'Egg',
            legume: 'Legume',
            dairy_product: 'Dairy product',
            cereal: 'Cereal',
            drink: 'Drink',
            rice_pasta: 'Rice - Pasta',
            null: 'No selected type',
        },
        recipeType: { entree: 'Entree', mainCourse: 'Main course', dessert: 'Dessert', sauce: 'Sauce', drink: 'Drink' },
    },
    components: {
        account: {
            openMenu: 'Open user menu',
            settings: 'Settings',
            signout: 'Sign out',
            signin: 'Sign in',
        },
    },
    header: {
        recipes: 'Recipes',
        home: 'Home',
        addRecipe: 'Share a recipe',
        admin: 'Admin',
        stocks: 'Stocks',
    },
    alert: {
        success: 'Success',
        error: 'Error',
        warning: 'Warning',
        info: 'Information',
    },
    pages: {
        adminUsers: {
            search: 'Search',
            create: 'Create',
            placeholder: {
                users: 'Theo, Admin, ...',
            },
        },
        adminUser: {
            cancel: 'Cancel',
            username: 'Username',
            password: 'Password',
            role: 'Role',
            update: 'Update',
            create: 'Create',
            delete: 'Delete',
            activated: 'Active',
            updated: {
                success: 'User updated !',
                error: 'Oops.. Error while updating the user..',
            },
            created: {
                success: 'User created !',
                error: 'Oops.. Error while creating the user..',
            },
            removed: {
                success: 'User removed !',
                error: 'Oops.. Error while removing the user..',
            },
            modal: {
                delete: 'Suppression',
                desc: 'Are you sure you want to delete {username} ?',
                cancel: 'Cancel',
                confirm: 'Delete',
            },
        },
        adminRoles: {
            default: 'Default',
            scopes: '{nb} scopes',
            create: 'Create',
            search: 'Search',
            placeholder: {
                roles: 'Admin, user, ...',
            },
        },
        adminRole: {
            cancel: 'Cancel',
            name: 'Role name',
            update: 'Update',
            create: 'Create',
            delete: 'Delete',
            scopes: 'Scopes',
            default: 'Default',
            updated: {
                success: 'Role updated !',
                error: 'Oops.. Error while updating the role..',
            },
            created: {
                success: 'Role created !',
                error: 'Oops.. Error while creating the role..',
            },
            removed: {
                success: 'Role removed !',
                error: 'Oops.. Error while removing the role..',
            },
            modal: {
                delete: 'Suppression',
                desc: 'Are you sure you want to delete {label} ?',
                cancel: 'Cancel',
                confirm: 'Delete',
            },
        },
        adminScopes: {
            search: 'Search',
            create: 'Create',
            placeholder: {
                scopes: 'admin.cache.read, user.recipe.read, ...',
            },
        },
        adminScope: {
            cancel: 'Cancel',
            name: 'Scope name',
            update: 'Update',
            create: 'Create',
            delete: 'Delete',
            updated: {
                success: 'Scope updated !',
                error: 'Oops.. Error while updating the scope..',
            },
            created: {
                success: 'Scope created !',
                error: 'Oops.. Error while creating the scope..',
            },
            removed: {
                success: 'Scope removed !',
                error: 'Oops.. Error while removing the scope..',
            },
            modal: {
                delete: 'Suppression',
                desc: 'Are you sure you want to delete {label} ?',
                cancel: 'Cancel',
                confirm: 'Delete',
            },
        },
        adminFoods: {
            search: 'Search',
            create: 'Create',
            placeholder: {
                foods: 'Ail, Camembert, ...',
            },
        },
        adminFood: {
            cancel: 'Cancel',
            name: 'Food name',
            update: 'Update',
            create: 'Create',
            delete: 'Delete',
            activated: 'Active',
            genericFood: 'Generic food',
            basicFood: 'Basic food (is it unprocessed?)',
            foodType: 'Food type',
            diet: 'Diet',
            isVegan: 'Is vegan',
            isPorkless: 'Is porkless',
            isDairyFree: 'Is dairy free',
            isGlutenFree: 'Is gluten free',
            isVegetarian: 'Is vegetarian',
            updated: {
                success: 'Food updated !',
                error: 'Oops.. Error while updating the food..',
            },
            created: {
                success: 'Food created !',
                error: 'Oops.. Error while creating the food..',
            },
            removed: {
                success: 'Food removed !',
                error: 'Oops.. Error while removing the food..',
            },
            modal: {
                delete: 'Suppression',
                desc: 'Are you sure you want to delete {foodName} ?',
                cancel: 'Cancel',
                confirm: 'Delete',
            },
        },
        adminIngredientUnits: {
            create: 'Create',
            search: 'Search',
            placeholder: {
                roles: 'L, g, ...',
            },
        },
        adminIngredientUnit: {
            cancel: 'Cancel',
            name: 'Unit name',
            update: 'Update',
            create: 'Create',
            delete: 'Delete',
            updated: {
                success: 'Unit updated !',
                error: 'Oops.. Error while updating the unit..',
            },
            created: {
                success: 'Unit created !',
                error: 'Oops.. Error while creating the unit..',
            },
            removed: {
                success: 'Unit removed !',
                error: 'Oops.. Error while removing the unit..',
            },
            modal: {
                delete: 'Suppression',
                desc: 'Are you sure you want to delete {label} ?',
                cancel: 'Cancel',
                confirm: 'Delete',
            },
        },
        adminRecipes: {
            search: 'Search',
            create: 'Create',
            placeholder: {
                recipes: 'Hamburger, Pasta cheese, ...',
            },
        },
        adminRecipe: {
            cancel: 'Cancel',
            title: 'Title',
            update: 'Update',
            create: 'Create',
            delete: 'Delete',
            activated: 'Active',
            recipeType: 'Recipe type',
            updated: {
                success: 'Recipe updated !',
                error: 'Oops.. Error while updating the recipe..',
            },
            created: {
                success: 'Food created !',
                error: 'Oops.. Error while creating the recipe..',
            },
            removed: {
                success: 'Food removed !',
                error: 'Oops.. Error while removing the recipe..',
            },
            modal: {
                delete: 'Suppression',
                desc: 'Are you sure you want to delete {recipeTitle} ?',
                cancel: 'Cancel',
                confirm: 'Delete',
            },
        },
        signup: {
            error: {
                differentPassword: 'These passwords do not match. Try Again.',
            },
            password: 'Password',
            confirmPassword: 'Confirm password',
            nickname: 'Nickname',
            signup: 'Sign up',
            title: 'Registration',
            alreadyAccount: 'Already have an account ?',
            connectNow: 'Sign in now',
        },
        signin: {
            error: {
                forbiddenConnection: 'Incorrect username or password.',
            },
            welcome: 'Account successfully created !',
            title: 'Log in',
            password: 'Password',
            nickname: 'Nickname',
            rememberMe: 'Remember me',
            forgotPassword: 'Forgot your password ?',
            signin: 'Sign in',
            register: 'Register now',
            noAccount: 'No account yet?',
        },
        settings: {
            platform: 'Plateform',
            browser: 'Browser',
            os: 'System',
            expireAt: 'Expire at',
            profile: 'Profile',
            security: 'Security',
            myAccount: 'My account',
            latestConnections: 'Latest connections',
            active: 'Active',
            revoked: 'Revoked',
            expired: 'Expired',
            status: 'Status',
            nickname: 'Nickname',
            limitedLast: 'Limited to the last 10',
            createdAt: 'Created at',
            updatedAt: 'Updated at',
            personalInformation: 'Personal information',
            role: 'Role',
            password: 'Password',
            oldPassword: 'Old password',
            newPassword: 'New password',
            update: 'Update',
            updatePassword: {
                success: 'Password updated !',
                error: 'Oops.. Error while updating password..',
            },
        },
        stocks: {
            eatQuickly: 'To eat quickly',
            myStock: 'My stock',
            addFood: 'Add food',
            purchaseDate: 'Purchase date',
            peremptionDate: 'Peremption date',
            quantity: 'Quantity',
            unit: 'Unit',
            add: 'Add',
            storageArea: 'Storage area',
            created: {
                success: 'Food added !',
                error: 'Oops.. Error while adding the food..',
            },
        },
        addRecipe: {
            recipe: 'Recipe',
            addPicture: 'Add picture',
            ingredients: 'Ingredients',
            addIngredient: 'Add ingredient',
            ingredientsSample: 'Mozzarella, tomate, moutarde, beurre,...',
            steps: 'Steps',
            validation: 'Validation',
            title: 'Title',
            cookingTime: 'Cooking time',
            preparationTime: 'Preparation time',
            howManyPerson: 'For how many people?',
            veggie: 'Veggie',
            alcoholic: 'Alcoholic',
            recipeType: 'Recipe type',
            next: 'Next',
            previous: 'Previous',
            create: 'Create',
            update: 'Update',
            titleStep: 'Title step',
            libStep: 'Description',
            addStep: 'Add step',
            awesomeRecipe: 'Your recipe is so awesome!',
            noTitle: 'Arf a title seems necessary to me unfortunately',
            noIngredient: "A recipe without ingredients? Who would've believed that ?",
            noStep: 'Whoa, no step? Seriously?',
            optional: 'Optional',
            cancel: 'Cancel',
            updated: {
                success: 'Recipe updated !',
                error: 'Oops.. Error while updating the recipe..',
            },
            created: {
                success: 'Recipe created !',
                error: 'Oops.. Error while creating the recipe..',
            },
        },
        recipes: {
            recipes: 'Recipes',
            ingredients: 'Ingredients',
            placeholder: {
                recipes: 'Burger, risotto, pizza,...',
                ingredients: 'Tomato, rice, cheese,...',
            },
            search: 'Search',
        },
        recipe: {
            ingredients: 'Ingredients',
            preparation: 'Preparation',
            return: 'Return to recipes',
            edit: 'Edit',
            delete: 'Delete',
            nbPersons: 'For {nb} people',
            optional: 'Optional',
            removed: {
                success: 'Recipe removed !',
                error: 'Oops.. Error while removing the recipe..',
            },
            modal: {
                delete: 'Suppression',
                desc: 'Are you sure you want to delete {recipeName} ?',
                cancel: 'Cancel',
                confirm: 'Delete',
            },
        },
        home: {
            title: 'What are we going to eat today?',
            lastestRecipesAdded: 'Latest recipes added',
            someRecipesYouMightLike: 'Here are some recipes you might like',
            surplusOf: 'Surplus of {foodName}',
            seeMore: 'See more recipes',
            sharedRecipes: 'Shared recipes',
            seasonalFoods: 'Seasonal foods',
        },
    },
};

export default en;
