import { action, Action, thunk, Thunk } from 'easy-peasy';
import { Injections } from '../injections';

import { Role } from './Role.type';

export interface RoleModel {
    roles: Role[];
    count: number | undefined;
    lastFetchedTime: Date | undefined;
    setRoles: Action<RoleModel, Role[]>;
    setCount: Action<RoleModel, number>;
    setLastFetchedTime: Action<RoleModel, void>;
    fetch: Thunk<RoleModel, void, Injections>;
    fetchById: Thunk<RoleModel, string, Injections>;
    create: Thunk<RoleModel, Role, Injections>;
    update: Thunk<RoleModel, Role, Injections>;
    remove: Thunk<RoleModel, string, Injections>;
}

export const roleModel: RoleModel = {
    roles: [],
    count: undefined,
    lastFetchedTime: undefined,
    setRoles: action((state, payload: Role[]) => {
        state.roles = payload;
    }),
    setCount: action((state, payload: number) => {
        state.count = payload;
    }),
    setLastFetchedTime: action((state, _payload) => {
        state.lastFetchedTime = new Date();
    }),
    fetch: thunk(async (actions, _payload, { injections }) => {
        try {
            const { roleService } = injections;
            const roles = await roleService.fetch();
            actions.setCount(roles.data[1]);
            actions.setRoles(roles.data[0]);
            actions.setLastFetchedTime();
        } catch (error) {
            console.error(error);
        }
    }),
    fetchById: thunk(async (actions, payload, { injections }) => {
        try {
            const { roleService } = injections;
            const role = await roleService.fetchById(payload);
            return role.data;
        } catch (error) {
            console.error(error);
        }
    }),
    create: thunk(async (actions, payload, { injections }) => {
        try {
            const { roleService } = injections;
            const role = await roleService.create(payload);
            await actions.fetch();
            return role.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
    update: thunk(async (actions, payload, { injections }) => {
        try {
            const { roleService } = injections;
            const role = await roleService.update(payload);
            await actions.fetch();
            return role.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
    remove: thunk(async (actions, payload, { injections }) => {
        try {
            const { roleService } = injections;
            const role = await roleService.delete(payload);
            await actions.fetch();
            return role.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
};

export default roleModel;
