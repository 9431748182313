import { Component, PropsWithChildren, ComponentType, ErrorInfo } from 'react';
import ErrorComponent from '../Error';

interface ErrorBoundaryProps {
    FallBackComponent?: ComponentType<any>;
    onError?: (error: Error, componentStack: string) => void;
}

interface ErrorBoundaryState {
    error?: Error;
}

class ErrorBoundaryComponent extends Component<PropsWithChildren<ErrorBoundaryProps>, ErrorBoundaryState> {
    state = {
        error: undefined,
    };

    static getDerivedStateFromError(error: Error) {
        return { error: error };
    }

    componentDidCatch(error: Error, info: ErrorInfo): void {
        const { onError } = this.props;
        if (onError) {
            try {
                onError(error, info ? info.componentStack : '');
            } catch (error) {}
        }
    }

    render() {
        const { children, FallBackComponent = ErrorComponent } = this.props;
        const { error } = this.state;
        if (error != null) {
            return <FallBackComponent />;
        }
        return <>{children}</>;
    }
}

export default ErrorBoundaryComponent;
