import React, { useCallback, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AlertContainer from './AlertContainer';

export type AlertProviderProps = {
    children: React.ReactNode;
};

type AlertMessageType = 'info' | 'success' | 'warning' | 'error';

export type Alert = {
    id: string;
    lifetime: number;
    message: string | React.ReactNode;
    type?: AlertMessageType;
    icon?: string;
    header?: string;
};

export type AlertContextType = {
    data: Array<Alert>;
    pushError(message: string, lifetime?: number): void;
    pushWarning(message: string, lifetime?: number): void;
    pushSuccess(message: string, lifetime?: number): void;
    pushInfo(message: string, lifetime?: number): void;
    push(message: string, type: AlertMessageType, lifetime?: number): void;
    pushCustom(message: string | React.ReactNode, lifetime: number, icon?: string | React.ReactNode): void;
    remove(id: string): void;
};

export const AlertContext = React.createContext<AlertContextType | undefined>(undefined);

export const useAlert = () => useContext(AlertContext);

const DEFAULT_INTERVAL = 5000;

export default function AlertProvider({ children }: AlertProviderProps) {
    const [data, setData] = useState<Array<Alert>>([]);

    const Push = useCallback(
        (message: string, type: AlertMessageType, lifetime?: number) => {
            if (message) {
                const uuid = uuidv4();
                const new_item: Alert = {
                    id: uuid,
                    message: message,
                    type: type,
                    lifetime: lifetime ? lifetime : DEFAULT_INTERVAL,
                };

                setData((prevState) => [...prevState, new_item]);
            }
        },
        [setData],
    );

    const PushCustom = useCallback(
        (message: string | React.ReactNode, lifetime?: number, icon?: string) => {
            if (message) {
                const uuid = uuidv4();
                const new_item: Alert = {
                    id: uuid,
                    message: message,
                    lifetime: lifetime ? lifetime : DEFAULT_INTERVAL,
                    icon: icon,
                    type: undefined,
                };

                setData((prevState) => [...prevState, new_item]);
            }
        },
        [setData],
    );

    const PushError = useCallback((message: string, lifetime?: number) => Push(message, 'error', lifetime), [Push]);
    const PushWarning = useCallback((message: string, lifetime?: number) => Push(message, 'warning', lifetime), [Push]);
    const PushSuccess = useCallback((message: string, lifetime?: number) => Push(message, 'success', lifetime), [Push]);
    const PushInfo = useCallback((message: string, lifetime?: number) => Push(message, 'info', lifetime), [Push]);

    const AlertContexd = useCallback(() => {
        return {
            data: data,
            pushError: PushError,
            pushWarning: PushWarning,
            pushSuccess: PushSuccess,
            pushInfo: PushInfo,
            push: Push,
            pushCustom: PushCustom,

            async remove(id: string) {
                setData((prevState) => prevState.filter((e) => e.id !== id));
            },
        };
    }, [data, setData, PushError, PushWarning, PushSuccess, PushInfo, Push, PushCustom]);

    return (
        <AlertContext.Provider value={AlertContexd()}>
            <AlertContainer />
            {children}
        </AlertContext.Provider>
    );
}
