import axios, { AxiosResponse } from 'axios';
import { Role } from '../store/Role/Role.type';
import serverService from './server.service';

export class RoleService {
    fetch(limit: number = 10, page: number = 1): Promise<AxiosResponse<[Role[], number]>> {
        const skip = limit * (page - 1);
        return axios.get(`${serverService.getServer()}/roles?limit=${limit}&skip=${skip}`);
    }

    fetchById(roleId: string): Promise<AxiosResponse<Role>> {
        return axios.get(`${serverService.getServer()}/roles/${roleId}`);
    }

    create(role: Role): Promise<AxiosResponse<Role>> {
        return axios.post(`${serverService.getServer()}/roles`, role);
    }

    update(role: Role): Promise<AxiosResponse<Role>> {
        return axios.put(`${serverService.getServer()}/roles/${role.role_id}`, role);
    }

    delete(roleId: string): Promise<AxiosResponse<Role>> {
        return axios.delete(`${serverService.getServer()}/roles/${roleId}`);
    }
}

export default RoleService;
