import { Userinfo } from '../../store/Userinfo/Userinfo.type';

export class GuardRulesUsecase {
    private readonly user: Userinfo;
    private readonly authorizedPermissions?: string[];
    private readonly callBack?: (user: Userinfo) => boolean;

    constructor(guardRulesBuilder: GuardRulesBuilder) {
        this.user = guardRulesBuilder.user;
        this.authorizedPermissions = guardRulesBuilder.permissions;
        this.callBack = guardRulesBuilder.callBack;
    }

    isAuthorized(): boolean {
        if (this.checkPermissions()) {
            return this.checkUsingCallBack();
        }
        return false;
    }

    private checkUsingCallBack(): boolean {
        if (this.callBack) return this.callBack(this.user);
        return true;
    }

    private checkPermissions(): boolean {
        if (this.authorizedPermissions && this.authorizedPermissions.length > 0) {
            return this.authorizedPermissions.some((permissionName): boolean =>
                this.containsResourceDotPermissionName(permissionName),
            );
        }
        return true;
    }

    extractFlatPermissionsPrefixedByResourceName(): string[] {
        const userScopes: string[] = [];
        if (this.user?.role?.scopes) {
            this.user.role.scopes.forEach((scope) => userScopes.push(scope.label.trim().toLowerCase()));
        }
        return userScopes;
    }

    private containsResourceDotPermissionName(permissionName: string) {
        const permissions = this.extractFlatPermissionsPrefixedByResourceName();
        return permissions.some((permission) => permission === permissionName);
    }
}

export class GuardRulesBuilder {
    private readonly userInformation: Userinfo;
    private authorizedPermissions?: string[];
    private customCallBack?: (user: Userinfo) => boolean;

    constructor(userInfo: Userinfo) {
        this.userInformation = userInfo;
    }

    authorizePermissions(permissions: string[]): GuardRulesBuilder {
        this.authorizedPermissions = permissions;
        return this;
    }

    shouldSatisfy(callback: (userInfo: Userinfo) => boolean): GuardRulesBuilder {
        this.customCallBack = callback;
        return this;
    }

    build(): GuardRulesUsecase {
        return new GuardRulesUsecase(this);
    }

    get user(): Userinfo {
        return this.userInformation;
    }

    get permissions(): string[] {
        return this.authorizedPermissions || [];
    }

    get callBack(): (userInfo: Userinfo) => boolean {
        //@ts-ignore
        return this.customCallBack;
    }
}
