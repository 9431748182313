import { action, Action } from 'easy-peasy';

import translationMessages, { MessageKeyValue } from '../../i18n';

export interface I18nModel {
    locale: string;
    messages: MessageKeyValue;
    update: Action<I18nModel, string>;
}

// Set locale default
let defaultLocale = 'en';
if (window.navigator.language.indexOf('fr') >= 0 || window.navigator.language.indexOf('en') >= 0) {
    defaultLocale = 'en';
}
if (window.navigator.language.indexOf('fr') >= 0) {
    defaultLocale = 'fr';
}

export const i18nModel: I18nModel = {
    locale: defaultLocale,
    messages: translationMessages[defaultLocale] as any,
    update: action((state, payload) => {
        state.locale = payload;
        state.messages = translationMessages[payload] as any;
    }),
};

export default i18nModel;
