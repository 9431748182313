import React from 'react';
import { Guard } from '../Guard';
import { useGuardService } from '../useGuardService';
import { Userinfo } from '../../../store/Userinfo/Userinfo.type';
import { useStoreState } from '../../../store/hooks';

export interface ProtectedComponentProps {
    mustHaveOneOf?: string[];
    mustSatisfy?: (userInfo: Userinfo) => boolean;
}

export const ProtectedComponent: React.FunctionComponent<ProtectedComponentProps> = ({
    mustHaveOneOf,
    mustSatisfy,
    children,
}) => {
    const { user } = useStoreState(({ userinfo }) => userinfo);
    const isAuthorized = useGuardService(user, mustHaveOneOf, mustSatisfy);

    return <Guard isAuthorized={isAuthorized}>{children}</Guard>;
};
