import { action, Action, thunk, Thunk } from 'easy-peasy';
import { Injections } from '../injections';

import { User } from './User.type';

export interface UserModel {
    users: User[];
    count: number | undefined;
    lastFetchedTime: Date | undefined;

    fetchAll: Thunk<UserModel, { limit: number; page: number }, Injections>;
    setCount: Action<UserModel, number>;
    setLastFetchedTime: Action<UserModel, void>;
    setUsers: Action<UserModel, User[]>;
    fetchById: Thunk<UserModel, string, Injections>;
    fetchUser: Thunk<UserModel, string, Injections>;
    create: Thunk<UserModel, User, Injections>;
    update: Thunk<UserModel, User, Injections>;
    remove: Thunk<UserModel, string, Injections>;
}

export const userModel: UserModel = {
    users: [],
    count: undefined,
    lastFetchedTime: undefined,

    setCount: action((state, payload: number) => {
        state.count = payload;
    }),
    setLastFetchedTime: action((state, _payload) => {
        state.lastFetchedTime = new Date();
    }),
    setUsers: action((state, payload: User[]) => {
        state.users = payload;
    }),

    fetchAll: thunk(async (actions, payload, { injections }) => {
        try {
            const { userService } = injections;
            const users = await userService.fetch(payload.limit, payload.page);
            actions.setCount(users.data[1]);
            actions.setUsers(users.data[0]);
            actions.setLastFetchedTime();
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
    fetchById: thunk(async (actions, payload, { injections }) => {
        try {
            const { userService } = injections;
            const user = await userService.fetchById(payload);
            return user.data;
        } catch (error) {
            console.error(error);
        }
    }),
    fetchUser: thunk(async (actions, payload, { injections }) => {
        try {
            const { userService } = injections;
            const user = await userService.fetchByUsername(payload);
            return user.data;
        } catch (error) {
            console.error(error);
        }
    }),
    create: thunk(async (actions, payload, { injections }) => {
        try {
            const { userService } = injections;
            const user = await userService.create(payload);
            await actions.fetchAll({ limit: 100, page: 1 });
            return user.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
    update: thunk(async (actions, payload, { injections }) => {
        try {
            const { userService } = injections;
            const user = await userService.update(payload);
            await actions.fetchAll({ limit: 100, page: 1 });
            return user.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
    remove: thunk(async (actions, payload, { injections }) => {
        try {
            const { userService } = injections;
            const user = await userService.delete(payload);
            await actions.fetchAll({ limit: 100, page: 1 });
            return user.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
};

export default userModel;
