import axios, { AxiosResponse } from 'axios';
import { IngredientUnit } from '../store/IngredientUnit/IngredientUnit.type';
import serverService from './server.service';

export class IngredientUnitService {
    fetch(): Promise<AxiosResponse<[IngredientUnit[], number]>> {
        return axios.get(`${serverService.getServer()}/ingredient-units`);
    }

    fetchById(ingredientUnitId: string): Promise<AxiosResponse<IngredientUnit>> {
        return axios.get(`${serverService.getServer()}/ingredient-units/${ingredientUnitId}`);
    }

    create(ingredientUnit: IngredientUnit): Promise<AxiosResponse<IngredientUnit>> {
        return axios.post(`${serverService.getServer()}/ingredient-units`, ingredientUnit);
    }

    update(ingredientUnit: IngredientUnit): Promise<AxiosResponse<IngredientUnit[]>> {
        return axios.put(
            `${serverService.getServer()}/ingredient-units/${ingredientUnit.ingredient_unit_id}`,
            ingredientUnit,
        );
    }

    remove(ingredientUnit: IngredientUnit): Promise<AxiosResponse<IngredientUnit[]>> {
        return axios.delete(`${serverService.getServer()}/ingredient-units/${ingredientUnit.ingredient_unit_id}`);
    }
}

export default IngredientUnitService;
