import { action, Action, thunk, Thunk } from 'easy-peasy';
import { Injections } from '../injections';

import { Food } from './Food.type';

export interface FoodModel {
    foods: Food[];
    count: number | undefined;
    lastFetchedTime: Date | undefined;
    setFoods: Action<FoodModel, Food[]>;
    setCount: Action<FoodModel, number>;
    setLastFetchedTime: Action<FoodModel, void>;
    fetch: Thunk<FoodModel, void, Injections>;
    fetchById: Thunk<FoodModel, string, Injections>;
    create: Thunk<FoodModel, Food, Injections>;
    update: Thunk<FoodModel, Food, Injections>;
    remove: Thunk<FoodModel, string, Injections>;
}

export const foodModel: FoodModel = {
    foods: [],
    count: undefined,
    lastFetchedTime: undefined,
    setFoods: action((state, payload: Food[]) => {
        state.foods = payload;
    }),
    setCount: action((state, payload: number) => {
        state.count = payload;
    }),
    setLastFetchedTime: action((state, _payload) => {
        state.lastFetchedTime = new Date();
    }),
    fetch: thunk(async (actions, _payload, { injections }) => {
        try {
            const { foodService } = injections;
            const foods = await foodService.fetch();
            actions.setCount(foods.data[1]);
            actions.setFoods(foods.data[0]);
            actions.setLastFetchedTime();
        } catch (error) {
            console.error(error);
        }
    }),
    fetchById: thunk(async (actions, payload, { injections }) => {
        try {
            const { foodService } = injections;
            const food = await foodService.fetchById(payload);
            return food.data;
        } catch (error) {
            console.error(error);
        }
    }),
    create: thunk(async (actions, payload, { injections }) => {
        try {
            const { foodService } = injections;
            const food = await foodService.create(payload);
            await actions.fetch();
            return food.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
    update: thunk(async (actions, payload, { injections }) => {
        try {
            const { foodService } = injections;
            const food = await foodService.update(payload);
            await actions.fetch();
            return food.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
    remove: thunk(async (actions, payload, { injections }) => {
        try {
            const { foodService } = injections;
            const food = await foodService.delete(payload);
            await actions.fetch();
            return food.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }),
};

export default foodModel;
