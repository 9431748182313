import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { HeaderItem } from './HeaderItem';
import { Disclosure } from '@headlessui/react';

import logo from '../../assets/images/testIcon.png';
import { Account } from '../Account';
import { ProtectedComponent } from '../../guards/role-guards';

interface HeaderProps {
    items: HeaderItem[];
}

const Header: FC<HeaderProps> = (props) => {
    const { items } = props;

    return (
        <Disclosure as="nav" className="bg-white border-b">
            {({ open }) => (
                <>
                    <div className="mx-auto px-2 sm:px-6 lg:px-8">
                        <div className="relative flex items-center justify-between h-16">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <span className="material-icons block h-6 w-6">close</span>
                                    ) : (
                                        <span className="material-icons block h-6 w-6">menu</span>
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex-shrink-0 flex items-center">
                                    <NavLink to={`/`}>
                                        <>
                                            <img
                                                loading="lazy"
                                                className="block lg:hidden h-8 w-auto"
                                                src={logo}
                                                alt="Logo"
                                            />
                                            <img
                                                loading="lazy"
                                                className="hidden lg:block h-8 w-auto"
                                                src={logo}
                                                alt="Logo"
                                            />
                                        </>
                                    </NavLink>
                                </div>
                                <div className="hidden sm:block sm:ml-6">
                                    <div className="flex space-x-4">
                                        {items.map((item, index) =>
                                            item.mustHaveOneOf ? (
                                                <ProtectedComponent
                                                    mustHaveOneOf={item.mustHaveOneOf}
                                                    key={`mobile-navlink-header-${index}`}
                                                >
                                                    <NavLink
                                                        end={item.exact}
                                                        data-testid={`${item.link}-link`}
                                                        to={item.link}
                                                        className={({ isActive }) =>
                                                            `${
                                                                isActive ? 'text-green-600' : 'text-black'
                                                            } nav-link hover:text-green-600 py-2 px-4`
                                                        }
                                                        key={`mobile-navlink-header-${index}`}
                                                    >
                                                        <span>
                                                            <FormattedMessage
                                                                id={`${item.libId}`}
                                                                data-testid={`${item.libId}`}
                                                            />
                                                        </span>
                                                    </NavLink>
                                                </ProtectedComponent>
                                            ) : (
                                                <NavLink
                                                    end={item.exact}
                                                    data-testid={`${item.link}-link`}
                                                    to={item.link}
                                                    className={({ isActive }) =>
                                                        `${
                                                            isActive ? 'text-green-600' : 'text-black'
                                                        } nav-link hover:text-green-600 py-2 px-4`
                                                    }
                                                    key={`mobile-navlink-header-${index}`}
                                                >
                                                    <span>
                                                        <FormattedMessage
                                                            id={`${item.libId}`}
                                                            data-testid={`${item.libId}`}
                                                        />
                                                    </span>
                                                </NavLink>
                                            ),
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                {/* Profile dropdown */}
                                <Account />
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            {items.map((item, index) =>
                                item.mustHaveOneOf ? (
                                    <ProtectedComponent
                                        mustHaveOneOf={item.mustHaveOneOf}
                                        key={`navlink-header-${index}`}
                                    >
                                        <NavLink
                                            end={item.exact}
                                            data-testid={`${item.link}-link`}
                                            to={item.link}
                                            className={({ isActive }) =>
                                                `${
                                                    isActive ? 'text-green-600' : 'text-black'
                                                } nav-link hover:text-green-600 py-2 px-4`
                                            }
                                            key={`navlink-header-${index}`}
                                        >
                                            <span>
                                                <FormattedMessage id={`${item.libId}`} data-testid={`${item.libId}`} />
                                            </span>
                                        </NavLink>
                                    </ProtectedComponent>
                                ) : (
                                    <NavLink
                                        end={item.exact}
                                        data-testid={`${item.link}-link`}
                                        to={item.link}
                                        className={({ isActive }) =>
                                            `${
                                                isActive ? 'text-green-600' : 'text-black'
                                            } nav-link hover:text-green-600 py-2 px-4`
                                        }
                                        key={`navlink-header-${index}`}
                                    >
                                        <span>
                                            <FormattedMessage id={`${item.libId}`} data-testid={`${item.libId}`} />
                                        </span>
                                    </NavLink>
                                ),
                            )}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
};

export default Header;
